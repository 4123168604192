import { all, put, call, select, takeEvery } from 'redux-saga/effects'
import getMenuData from 'services/menu'
import { notification } from 'antd'
import { Log } from 'utils'
import actions from './actions'

export function* GET_DATA() {
  try {
    const state = yield select()
    const { groups } = state.auth
    const menuData = yield call(getMenuData, groups)
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuData,
        loading: false,
      },
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export default function* rootSaga() {
  try {
    yield all([
      // GET_DATA(), // run once on app load to fetch menu data
      takeEvery(actions.GET_DATA, GET_DATA),
    ])
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}
