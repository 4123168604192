const actions = {
  SET_STATE: 'password/SET_STATE',
  CHANGE_PASSWORD: 'password/CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'password/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAIL: 'password/CHANGE_PASSWORD_FAIL',

  NEW_PASSWORD: 'password/NEW_PASSWORD',
  NEW_PASSWORD_SUCCESS: 'password/NEW_PASSWORD_SUCCESS',
  NEW_PASSWORD_FAIL: 'password/NEW_PASSWORD_FAIL',
}

export default actions
