const actions = {
  SET_STATE: 'crud/SET_STATE',
  RESET: 'crud/RESET',
  RESET_LIST: 'crud/RESET_LIST',
  GENERATE_QR: 'crud/GENERATE_QR',

  SET_ITEM: 'crud/SET_ITEM',

  GET: 'crud/GET',
  GET_SUCCESS: 'crud/GET_SUCCESS',
  GET_FAIL: 'crud/GET_FAIL',

  LIST: 'crud/LIST',
  LIST_SUCCESS: 'crud/LIST_SUCCESS',
  LIST_FAIL: 'crud/LIST_FAIL',

  CREATE: 'crud/CREATE',
  CREATE_SUCCESS: 'crud/CREATE_SUCCESS',
  CREATE_FAIL: 'crud/CREATE_FAIL',

  CREATE_PURCHASE_ORDER: 'crud/CREATE_PURCHASE_ORDER',
  CREATE_PURCHASE_ORDER_SUCCESS: 'crud/CREATE_PURCHASE_ORDER_SUCCESS',
  CREATE_PURCHASE_ORDER_FAIL: 'crud/CREATE_PURCHASE_ORDER_FAIL',

  PAY_PURCHASE_ORDER: 'crud/PAY_PURCHASE_ORDER',
  PAY_PURCHASE_ORDER_SUCCESS: 'crud/PAY_PURCHASE_ORDER_SUCCESS',
  PAY_PURCHASE_ORDER_FAIL: 'crud/PAY_PURCHASE_ORDER_FAIL',

  REMOVE_PURCHASE_ORDER: 'crud/REMOVE_PURCHASE_ORDER',
  REMOVE_PURCHASE_ORDER_SUCCESS: 'crud/REMOVE_PURCHASE_ORDER_SUCCESS',
  REMOVE_PURCHASE_ORDER_FAIL: 'crud/REMOVE_PURCHASE_ORDER_FAIL',

  UPDATE_ORDER: 'crud/UPDATE_ORDER',
  UPDATE_ORDER_SUCCESS: 'crud/UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAIL: 'crud/UPDATE_ORDER_FAIL',

  UPDATE: 'crud/UPDATE',
  UPDATE_SUCCESS: 'crud/UPDATE_SUCCESS',
  UPDATE_FAIL: 'crud/UPDATE_FAIL',

  CREATE_CUSTOMER: 'crud/CREATE_CUSTOMER',
  CREATE_CUSTOMER_SUCCESS: 'crud/CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_FAIL: 'crud/CREATE_CUSTOMER_FAIL',

  CREATE_DRIVER: 'crud/CREATE_DRIVER',
  CREATE_DRIVER_SUCCESS: 'crud/CREATE_DRIVER_SUCCESS',
  CREATE_DRIVER_FAIL: 'crud/CREATE_DRIVER_FAIL',

  REMOVE: 'crud/REMOVE',
  REMOVE_SUCCESS: 'crud/REMOVE_SUCCESS',
  REMOVE_FAIL: 'crud/REMOVE_FAIL',

  // CREATE_EVIDENCE: 'crud/CREATE_EVIDENCE',
  // CREATE_EVIDENCE_SUCCESS: 'crud/CREATE_EVIDENCE_SUCCESS',
  // CREATE_EVIDENCE_FAIL: 'crud/CREATE_EVIDENCE_FAIL',
}

export default actions
