import actions from './actions'

const initialState = {
  loading: false,
  fetched: false,
  item: [],
  error: null,
}

export default function passwordReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    /*  case actions.CHANGE_PASSWORD:
      return { ...state, loading: true }  */
    /*  case actions.CHANGE_PASSWORD_SUCCESS:
      return { ...state, item: action.payload, loading: false, fetched: true }
    case actions.CHANGE_PASSWORD_FAIL:
      return { ...state, loading: false, fetched: false } */
    case actions.NEW_PASSWORD:
      return { ...state, loading: true, error: null }
    case actions.NEW_PASSWORD_SUCCESS:
      return { ...state, item: action.payload, loading: false, fetched: true }
    case actions.CHANGE_PASSWORD_SUCCESS:
      return { ...state, item: action.payload, loading: false, fetched: true }
    case actions.CHANGE_PASSWORD_FAIL:
      return { ...state, error: action.error, loading: false, fetched: false }
    case actions.NEW_PASSWORD_FAIL:
      return { ...state, error: action.error, loading: false, fetched: false }
    default:
      return state
  }
}
