import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { changePassword, newPassword } from 'services/changePassword'
import { Log } from 'utils'
import actions from './actions'

export function* CHANGE_PASSWORD({ payload }) {
  try {
    yield put({
      type: 'password/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const success = yield call(changePassword, payload.email)
    if (success) {
      yield put({
        type: 'password/CHANGE_PASSWORD_SUCCESS',
        payload: success,
      })
    }
  } catch (error) {
    yield put({
      type: actions.CHANGE_PASSWORD_FAIL,
      error,
    })
    let message = 'Ha ocurrido un error al enviar el código.'
    if (error && error.code === 'UserNotFoundException') {
      message = 'Usuario no encontrado'
    }

    Log(error)

    notification.warning({
      message: 'Error',
      description: message,
    })
  }
}

export function* NEW_PASSWORD({ payload }) {
  try {
    yield put({
      type: 'password/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const success = yield newPassword(payload)
    yield put({
      type: actions.NEW_PASSWORD_SUCCESS,
      payload: success,
    })
  } catch (error) {
    yield put({
      type: actions.NEW_PASSWORD_FAIL,
      error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al cambair la contraseña.',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
    takeEvery(actions.NEW_PASSWORD, NEW_PASSWORD),
  ])
}
