export const Customer = {
  name: 'customer',
  roles: ['Qiwenty'],
  // roles: [1, 2],
  fields: [
    { name: 'name', label: 'name', placeholder: 'customer.placeholder.name', required: true },
    { name: 'email', label: 'email', placeholder: 'customer.placeholder.email' },
    {
      name: 'fixedPrice',
      label: 'fixed.price',
      type: 'checkbox',
      placeholder: 'customer.fixed.price',
    },
    // { name: 'id', type: 'id', label: 'id.id', placeholder: 'id.id' },
  ],
  menu: {
    title: 'Clientes',
    key: 'customers',
    icon: 'fe fe-menu',
    url: '/customers',
  },
}

export const PurchaseOrder = {
  name: 'purchaseOrder',
  roles: ['Qiwenty'],
  fields: [
    // { name: 'id', labe: 'id', placeholder: 'purchaseorder.placeholder.id', required: true },
    { name: 'name', label: 'name', placeholder: 'purchaseorder.placeholder.name', required: true },
    {
      name: 'status',
      label: 'status',
      placeholder: 'purchaseorder.placeholder.status',
      required: true,
    },
    {
      name: 'driverId',
      label: 'driver',
      type: 'select',
      model: 'driver',
      placeholder: 'purchaseorder.placeholder.driver',
      views: [1, 2, 4],
    },
    {
      name: 'amount',
      label: 'amount',
      placeholder: 'purchaseorder.placeholder.amount',
      required: true,
    },
  ],
  menu: {
    title: 'Ordenes de pago',
    key: 'purchaseOrders',
    icon: 'fe fe-layers',
    url: '/purchseorders',
  },
}

export const Order = {
  name: 'order',
  roles: ['Qiwenty'],
  fields: [
    { name: 'name', label: 'name', placeholder: 'order.placeholder.name', required: true },
    {
      name: 'pickupDate',
      type: 'date',
      label: 'order.pickup.date',
      placeholder: 'order.placeholder.pickup',
      required: true,
    },
    {
      name: 'destinyDate',
      type: 'date',
      label: 'order.date',
      placeholder: 'order.placeholder.date',
      required: true,
    },
    // { name: 'customerId', type: 'hidden', views: [4], get: 'userId', default: true },
    {
      name: 'customerId',
      label: 'customer',
      type: 'select',
      model: 'customer',
      required: true,
      placeholder: 'warehouse.placeholder.customer',
      views: [4],
    },
    {
      name: 'warehouseId',
      label: 'warehouse',
      type: 'select',
      model: 'warehouse',
      required: true,
      placeholder: 'order.placeholder.warehouse',
      views: [4],
    },
    {
      name: 'price',
      label: 'price',
      type: 'number',
      placeholder: 'order.placeholder.price',
      required: true,
    },
    {
      name: 'regionId',
      label: 'region',
      type: 'select',
      model: 'region',
      required: true,
      placeholder: 'warehouse.placeholder.region',
      views: [4],
    },
    {
      name: 'cityId',
      label: 'city',
      type: 'select',
      model: 'city',
      // TODO: when use redux for list, we need these fields
      // fields: ['id', 'name'],
      required: true,
      placeholder: 'warehouse.placeholder.city',
      views: [4],
    },
    {
      name: 'address',
      label: 'address',
      placeholder: 'warehouse.placeholder.address',
      required: true,
    },
    // { name: 'id', type: 'id', label: 'id.id', placeholder: 'id.id' },
  ],
  menu: {
    title: 'Órdenes',
    key: 'orders',
    icon: 'fe fe-menu',
    url: '/orders',
  },
}
