import actions from './actions'

const initialState = {
  items: [],
  item: null,
  errors: null,
  loading: false,
  created: false,
  fetched: false,
}

const loadDeliveriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.LIST_SUCCESS:
      return { ...state, items: action.payload, loading: false, fetched: true }
    case actions.LIST_FAIL:
      return { ...state, errors: action.payload, loading: false }
    default:
      return state
  }
}

export default loadDeliveriesReducer
