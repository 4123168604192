import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch, BrowserRouter } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import * as models from 'models'
// import * as qiwentyModels from 'qiwentymodels'
// import * as customerModels from 'customermodels'

// import List from 'pages/qiwenty/crud/list'

import Layout from 'layouts'

// const routes = [
//   ...localRoutes,
//   // ...qiwentyAddRoutes,
//   // ...qiwentyUpdateRoutes,
//   // ...qiwentyRoutes,
//   ...adminAddRoutes,
//   ...adminRoutes,
//   ...adminUpdateRoutes,
//   ...customerRoutes,
//   ...qiwentyRoutes,
// ]

const mapStateToProps = ({ settings, auth }) => ({
  routerAnimation: settings.routerAnimation,
  groups: auth.groups,
})
const Router = ({ routerAnimation, groups }) => {
  const localRoutes = [
    // Dashboards
    {
      path: '/dashboard/alpha',
      Component: lazy(() => import('pages/dashboard/alpha')),
      exact: true,
    },

    // Auth Pages
    {
      path: '/auth/login',
      Component: lazy(() => import('pages/auth/login')),
      exact: true,
    },
    {
      path: '/auth/forgot-password',
      Component: lazy(() => import('pages/auth/forgot-password')),
      exact: true,
    },
    {
      path: '/auth/register',
      Component: lazy(() => import('pages/auth/register')),
      exact: true,
    },
    {
      path: '/auth/recover-confirm',
      Component: lazy(() => import('pages/auth/recover-confirm')),
      exact: true,
    },
    {
      path: '/auth/404',
      Component: lazy(() => import('pages/auth/404')),
      exact: true,
    },
    {
      path: '/auth/500',
      Component: lazy(() => import('pages/auth/500')),
      exact: true,
    },

    // // ADMINISTRATOR ROLE
    // {
    //   path: '/drivers',
    //   Component: lazy(() => import('pages/qiwenty/drivers')),
    //   exact: true,
    // },
    // {
    //   path: '/customers',
    //   Component: lazy(() => import('pages/qiwenty/customers')),
    //   exact: true,
    // },
    // {
    //   path: '/customers/add',
    //   Component: lazy(() => import('pages/qiwenty/customers/add')),
    //   exact: true,
    // },
    // {
    //   path: '/deliverys',
    //   Component: lazy(() => import('pages/qiwenty/deliverys')),
    //   exact: true,
    // },
    // {
    //   path: '/deliverys/add',
    //   Component: lazy(() => import('pages/qiwenty/deliverys/add')),
    //   exact: true,
    // },

    // // CUSTOMER ROLE
    // {
    //   path: '/warehouses',
    //   Component: lazy(() => import('pages/qiwenty/warehouses')),
    //   exact: true,
    // },
  ]

  // alert(JSON.stringify(models))

  // CUSTOMER, ADD & UPDATE
  const customerRoutes = groups.includes('Customer')
    ? [
        {
          path: '/orders',
          Component: lazy(() => import('components/roles/customers/orders/list')),
          model: models.Order,
          method: 'ordersByCustomer',
          canAdd: true,
          exact: true,
        },
        {
          path: '/orders/add',
          backPath: '/orders',
          Component: lazy(() => import('components/roles/customers/orders/add')),
          model: models.Order,
          exact: true,
        },
        /*  Vladimir */
        {
          path: '/orders/upload',
          backPath: '/orders',
          Component: lazy(() => import('components/roles/customers/orders/upload')),
          model: models.Order,
          exact: true,
        },
        /*  Vladimir */
        {
          path: '/orders/:id',
          backPath: '/orders',
          Component: lazy(() => import('components/roles/customers/orders/add')),
          model: models.Order,
          updateView: true,
          exact: true,
        },
        {
          path: '/warehouses',
          Component: lazy(() => import('components/roles/customers/warehouses/list')),
          model: models.Warehouse,
          method: 'warehousesByCustomer',
          canAdd: false,
          exact: true,
        },
        {
          path: '/warehouses/:id',
          backPath: '/warehouses',
          Component: lazy(() => import('components/roles/customers/warehouses/add')),
          model: models.Warehouse,
          updateView: true,
          exact: true,
        },
      ]
    : []

  const qiwentyRoutes = groups.includes('Qiwenty')
    ? [
        {
          path: '/orders',
          Component: lazy(() => import('components/roles/qiwenty/orders/list')),
          model: models.Order,
          exact: true,
        },
        {
          path: '/orders/add',
          backPath: '/orders',
          Component: lazy(() => import('components/roles/qiwenty/orders/add')),
          model: models.Order,
          exact: true,
        },
        {
          path: '/orders/:id',
          backPath: '/orders',
          Component: lazy(() => import('components/roles/qiwenty/orders/add')),
          model: models.Order,
          updateView: true,
          exact: true,
        },
        {
          path: '/purchaseorders',
          Component: lazy(() => import('components/roles/qiwenty/purchaseorders/list')),
          model: models.PurchaseOrder,
          exact: true,
        },
        {
          path: '/purchaseorders/add',
          backPath: '/purchaseorders',
          Component: lazy(() => import('components/roles/qiwenty/purchaseorders/add')),
          model: models.PurchaseOrder,
          exact: true,
        },
        {
          path: '/purchaseorders/:id',
          backPath: '/purchaseorders',
          Component: lazy(() => import('components/roles/qiwenty/purchaseorders/add')),
          model: models.PurchaseOrder,
          updateView: true,
          exact: true,
        },
        {
          path: '/warehouses',
          Component: lazy(() => import('components/roles/list')),
          model: models.Warehouse,
          canAdd: true,
          exact: true,
        },
        {
          path: '/warehouses/add',
          backPath: '/warehouses',
          Component: lazy(() => import('components/roles/qiwenty/warehouses/add')),
          model: models.Warehouse,
          exact: true,
        },
        {
          path: '/warehouses/:id',
          backPath: '/warehouses',
          Component: lazy(() => import('components/roles/qiwenty/warehouses/add')),
          model: models.Warehouse,
          updateView: true,
          exact: true,
        },
        {
          path: '/customers',
          Component: lazy(() => import('components/roles/list')),
          model: models.Customer,
          canAdd: true,
          exact: true,
        },
        {
          path: '/customers/add',
          backPath: '/customers',
          Component: lazy(() => import('components/roles/qiwenty/customers/add')),
          model: models.Customer,
          exact: true,
        },
        {
          path: '/customers/:id',
          backPath: '/customers',
          Component: lazy(() => import('components/roles/qiwenty/customers/add')),
          model: models.Customer,
          updateView: true,
          exact: true,
        },
        {
          path: '/drivers',
          Component: lazy(() => import('components/roles/list')),
          model: models.Driver,
          canAdd: true,
          exact: true,
        },
        {
          path: '/drivers/add',
          backPath: '/drivers',
          Component: lazy(() => import('components/roles/qiwenty/drivers/add')),
          model: models.Driver,
          exact: true,
        },
        {
          path: '/drivers/:id',
          backPath: '/drivers',
          Component: lazy(() => import('components/roles/qiwenty/drivers/add')),
          model: models.Driver,
          updateView: true,
          exact: true,
        },
        {
          path: '/evidences',
          Component: lazy(() => import('components/roles/qiwenty/evidences/list')),
          model: models.Evidence,
          canAdd: true,
          exact: true,
        },
        {
          path: '/evidences/add',
          backPath: '/evidences',
          Component: lazy(() => import('components/roles/qiwenty/evidences/add')),
          model: models.Evidence,
          exact: true,
        },
        {
          path: '/evidences/:id',
          backPath: '/evidences',
          Component: lazy(() => import('components/roles/qiwenty/evidences/add')),
          model: models.Evidence,
          updateView: true,
          exact: true,
        },
        {
          path: '/routes',
          Component: lazy(() => import('components/roles/qiwenty/routes/list')),
          model: models.Route,
          exact: true,
        },
        {
          path: '/payments',
          backPath: '/purchaseorders?status=draft',
          Component: lazy(() => import('components/roles/qiwenty/payments/list')),
          model: models.Order,
          exact: true,
        },
        {
          path: '/charges',
          Component: lazy(() => import('components/roles/qiwenty/charges/list')),
          model: models.Order,
          exact: true,
        },
      ]
    : []

  // const customerRoutes = Object.keys(customerModels).map(e => ({
  //   path: customerModels[e].menu.url,
  //   Component: lazy(() => import('components/qiwenty/crud/list')), // import(`pages/qiwenty/${e.menu.key}`)
  //   model: customerModels[e],
  //   exact: true,
  // }))

  // // CUSTOMER ADD
  // const customerAddRoutes = Object.keys(customerModels).map(e => ({
  //   path: `${customerModels[e].menu.url}/add`,
  //   Component: lazy(() => import(`components/qiwenty/${e.toLowerCase()}s/add`)),
  //   model: customerModels[e],
  //   exact: true,
  // }))

  // // CUSTOMER UPDATE
  // const customerUpdateRoutes = Object.keys(customerModels).map(e => ({
  //   path: `${customerModels[e].menu.url}/:id`,
  //   Component: lazy(() => import(`components/qiwenty/${e.toLowerCase()}s/add`)), // import(`pages/qiwenty/${e.menu.key}`)
  //   model: customerModels[e],
  //   updateView: true,
  //   exact: true,
  // }))

  // QiWENTY
  // const qiwentyRoutes = Object.keys(qiwentyModels).map(e => ({
  //   path: qiwentyModels[e].menu.url,
  //   Component: lazy(() => import('components/qiwenty/crud/list')), // import(`pages/qiwenty/${e.menu.key}`)
  //   model: qiwentyModels[e],
  //   exact: true,
  // }))

  // // QiWENTY ADD
  // const qiwentyAddRoutes = Object.keys(qiwentyModels).map(e => ({
  //   path: `${qiwentyModels[e].menu.url}/add`,
  //   Component: lazy(() => import(`components/qiwenty/${e.toLowerCase()}s/add`)),
  //   model: qiwentyModels[e],
  //   exact: true,
  // }))

  // // QiWENTY UPDATE
  // const qiwentyUpdateRoutes = Object.keys(qiwentyModels).map(e => ({
  //   path: `${qiwentyModels[e].menu.url}/:id`,
  //   Component: lazy(() => import(`components/qiwenty/${e.toLowerCase()}s/add`)), // import(`pages/qiwenty/${e.menu.key}`)
  //   model: qiwentyModels[e],
  //   updateView: true,
  //   exact: true,
  // }))

  // const qiwentyAddRoutes = [
  //   {
  //     path: '/customers/add',
  //     Component: lazy(() => import('components/qiwenty/customers/add')),
  //     model: models.Customer,
  //     exact: true,
  //   },
  // ]

  // ADMINISTRATION
  const adminListRoutes = Object.keys(models).map(e => ({
    path: models[e].menu.url,
    Component: lazy(() => import('components/qiwenty/crud/list')), // import(`pages/qiwenty/${e.menu.key}`)
    model: models[e],
    exact: true,
  }))

  // ADMINISTRATION ADD
  const adminAddRoutes = Object.keys(models).map(e => ({
    path: `${models[e].menu.url}/add`,
    Component: lazy(() => import('components/qiwenty/crud/add')), // import(`pages/qiwenty/${e.menu.key}`)
    model: models[e],
    exact: true,
  }))

  // ADMINISTRATION UPDATE
  const adminUpdateRoutes = Object.keys(models).map(e => ({
    path: `${models[e].menu.url}/:id`,
    Component: lazy(() => import('components/qiwenty/crud/add')), // import(`pages/qiwenty/${e.menu.key}`)
    model: models[e],
    updateView: true,
    exact: true,
  }))

  const userRoutes = [
    {
      path: '/users',
      Component: lazy(() => import('components/qiwenty/users/list')),
      exact: true,
    },
    {
      path: '/users/add',
      Component: lazy(() => import('components/qiwenty/users/add')),
      exact: true,
    },
    {
      path: '/users/:id',
      Component: lazy(() => import('components/qiwenty/users/add')),
      updateView: true,
      exact: true,
    },
  ]

  const adminRoutes = groups.includes('Admin')
    ? [...adminListRoutes, ...adminAddRoutes, ...adminUpdateRoutes]
    : []

  return (
    <BrowserRouter>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard/alpha" />} />
                    {localRoutes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    {adminRoutes.map(({ path, Component, exact, model, updateView }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component model={model} updateView={updateView} />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    {qiwentyRoutes.map(
                      ({ path, backPath, Component, exact, model, updateView, method, canAdd }) => (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={null}>
                                  <Component
                                    model={model}
                                    path={path}
                                    backPath={backPath}
                                    updateView={updateView}
                                    method={method}
                                    canAdd={canAdd}
                                  />
                                </Suspense>
                              </div>
                            )
                          }}
                        />
                      ),
                    )}
                    {customerRoutes.map(
                      ({ path, backPath, Component, exact, model, updateView, method, canAdd }) => (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={null}>
                                  <Component
                                    model={model}
                                    path={path}
                                    backPath={backPath}
                                    updateView={updateView}
                                    method={method}
                                    canAdd={canAdd}
                                  />
                                </Suspense>
                              </div>
                            )
                          }}
                        />
                      ),
                    )}
                    {userRoutes.map(({ path, Component, exact, updateView }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component updateView={updateView} />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}

                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </BrowserRouter>
  )
}

export default connect(mapStateToProps)(Router)
