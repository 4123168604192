import * as models from 'models'
// import * as qiwentyModels from 'qiwentymodels'
// import * as customerModels from 'customermodels'

export default async function getMenuData(groups) {
  const menu = [
    {
      category: true,
      title: 'Dashboard',
    },
    {
      title: 'Dashboard',
      icon: 'fe fe-home',
      roles: ['admin'],
      // count: 4,
      key: 'dashboard',
      url: '/dashboard/alpha',
    },
    // // QiWENTY
    // {
    //   category: true,
    //   title: 'QiWENTY',
    // },
    // {
    //   title: 'Clientes',
    //   key: 'admin/customers',
    //   icon: 'fe fe-menu',
    //   url: '/customers',
    // },
    // {
    //   title: 'Choferes',
    //   key: 'admin/drivers',
    //   icon: 'fe fe-menu',
    //   url: '/drivers',
    // },
    // {
    //   title: 'Despachos',
    //   key: 'admin/deliverys',
    //   icon: 'fe fe-menu',
    //   url: '/deliverys',
    // },

    // // Clientes
    // {
    //   category: true,
    //   title: 'Clientes',
    // },
    // {
    //   title: 'Despachos',
    //   key: 'customer/deliverys',
    //   icon: 'fe fe-menu',
    //   url: '/deliverys',
    // },
    // {
    //   title: 'Alamacenes',
    //   key: 'customer/warehouses',
    //   icon: 'fe fe-menu',
    //   url: '/warehouses',
    // },
    // // Choferes
    // {
    //   category: true,
    //   title: 'Chofer',
    // },
    // {
    //   title: 'Despachos',
    //   key: 'drivers/deliverys',
    //   icon: 'fe fe-menu',
    //   url: '/deliverys',
    // },
  ]

  const customerMenus = []
  const qiwentyMenus = []
  const adminMenus = []
  const usersMenus = []

  if (groups.includes('Customer')) {
    customerMenus.push({
      category: true,
      title: 'Qiwenty',
    })
    /*  customerMenus.push({
      title: 'Órdenes',
      key: 'orders',
      icon: 'fe fe-layers',
      url: `/orders`,
    })  */

    customerMenus.push({
      title: 'Órdenes',
      key: 'orders',
      icon: 'fe fe-layers',
      children: [
        {
          title: 'Borrador',
          key: 'ordersAssign',
          url: '/orders?status=draft',
        },
        {
          title: 'Procesadas',
          key: 'ordersBootstrap',
          url: '/orders?status=processed',
        },
        {
          title: 'Asignadas',
          key: 'ordersAssigned',
          url: '/orders?status=assigned',
        },
        {
          title: 'En transito',
          key: 'ordersTransit',
          url: '/orders?status=transit',
        },
        {
          title: 'Entregadas',
          key: 'ordersDelivered',
          url: '/orders?status=delivered',
        },
        {
          title: 'No Entregadas',
          key: 'ordersNotDelivered',
          url: '/orders?status=not_delivered',
        },
      ],
    })

    customerMenus.push({
      title: 'Almacenes',
      key: 'warehouses',
      icon: 'lnr lnr-apartment',
      url: '/warehouses',
    })
    /*  Vladimir  */

    customerMenus.push({
      title: 'Subir Orden',
      key: 'upload',
      icon: 'fe fe-upload',
      url: '/orders/upload',
    })
    /*  Vladimir  */
  }

  if (groups.includes('Qiwenty')) {
    qiwentyMenus.push({
      category: true,
      title: 'Qiwenty',
    })
    qiwentyMenus.push({
      title: 'Clientes',
      key: 'customers',
      icon: 'fe fe-users',
      url: `/customers`,
    })
    qiwentyMenus.push({
      title: 'Órdenes',
      key: 'orders',
      icon: 'fe fe-layers',
      children: [
        {
          title: 'Borrador',
          key: 'ordersAssign',
          url: '/orders?status=draft',
        },
        {
          title: 'Procesadas',
          key: 'ordersProccess',
          url: '/orders?status=processed',
        },
        {
          title: 'Asignadas',
          key: 'ordersBootstrap',
          url: '/orders?status=assigned',
        },
        {
          title: 'En transito',
          key: 'ordersTransit',
          url: '/orders?status=transit',
        },
        {
          title: 'Entregadas',
          key: 'ordersDelivered',
          url: '/orders?status=delivered',
        },
        {
          title: 'No entregadas',
          key: 'ordersNotDelivered',
          url: '/orders?status=not_delivered',
        },
        {
          title: 'Pendientes de Pago',
          key: 'ordersPaymentPending',
          url: '/orders?status=paymentPending',
        },
        {
          title: 'Pagadas',
          key: 'ordersPayed',
          url: '/orders?status=payed',
        },
      ],
    })

    qiwentyMenus.push({
      title: 'Almacenes',
      key: 'warehouses',
      icon: 'lnr lnr-apartment',
      url: '/warehouses',
    })
    qiwentyMenus.push({
      title: 'Choferes',
      key: 'drivers',
      icon: 'fe fe-users',
      url: '/drivers',
    })
    qiwentyMenus.push({
      title: 'Evidencias',
      key: 'evidences',
      icon: 'fe fe-file-text',
      url: '/evidences',
    })

    qiwentyMenus.push({
      title: 'Rutas',
      key: 'routes',
      icon: 'fe fe-map-pin',
      url: '/routes',
    })

    // qiwentyMenus.push({
    //   title: 'Pagos',
    //   key: 'payments',
    //   icon: 'fa fa-credit-card',
    //   children: [
    //     {
    //       title: 'Manejar pago',
    //       key: 'paymentsManage',
    //       url: '/payments',
    //     },
    //   ],
    // })
    qiwentyMenus.push({
      category: true,
      title: 'Pagos y Cobros',
    })
    qiwentyMenus.push({
      title: 'Pagos',
      key: 'payments',
      icon: 'fa fa-credit-card',
      children: [
        {
          title: 'Borrador',
          key: 'purchaseordersDraft',
          url: '/purchaseorders?status=draft',
        },
        {
          title: 'Pagado',
          key: 'purchaseordersPayed',
          url: '/purchaseorders?status=payed',
        },
      ],
    })
  }
  // Object.keys(customerModels).map(e => {
  //   const { roles } = customerModels[e]
  //   const allowMenu = roles === undefined || roles.some(o => groups.includes(o))
  //   if (allowMenu) {
  //     customerMenus.push(customerModels[e].menu)
  //   }
  //   return true
  // })

  // Object.keys(qiwentyModels).map(e => {
  //   const { roles } = qiwentyModels[e]
  //   const allowMenu = roles === undefined || roles.some(o => groups.includes(o))
  //   if (allowMenu) {
  //     qiwentyMenus.push(qiwentyModels[e].menu)
  //   }
  //   return true
  // })

  // if (customerMenus.length > 0) {
  //   customerMenus.splice(0, 0, {
  //     category: true,
  //     title: 'Qiwenty',
  //   })
  // }
  // if (qiwentyMenus.length > 0) {
  //   qiwentyMenus.splice(0, 0, {
  //     category: true,
  //     title: 'Qiwenty',
  //   })
  // }

  if (groups.includes('Admin')) {
    adminMenus.push({
      category: true,
      title: 'Administración',
    })
    Object.keys(models).map(e => adminMenus.push(models[e].menu))
  }

  /* Menus common to Qwenty and Admin */
  const roles = ['Admin', 'Qiwenty']
  if (roles.some(o => groups.includes(o))) {
    usersMenus.push(
      {
        category: true,
        title: 'Usuarios',
      },
      {
        title: 'Usuarios',
        key: 'users',
        icon: 'fe fe-users',
        url: `/users`,
      },
    )
  }

  return [...menu, ...customerMenus, ...qiwentyMenus, ...adminMenus, ...usersMenus]
}
