import actions from './actions'

const initialState = {
  items: [],
  itemsByIds: {},
  item: null,
  nextToken: null,
  errors: null,
  loading: false,
  created: false,
  fetched: false,
}

const crudReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    // case actions.GET:
    //   return { ...state, loading: true, error: null }
    case actions.LIST_SUCCESS: {
      const { items, nextToken, appendItems } = action.payload
      if (appendItems) {
        const { itemsByIds, items: tmpItems } = state
        const total = tmpItems.length
        items.forEach((e, index) => {
          itemsByIds[e.id] = index + total
        })
        const resultItems = tmpItems.concat(items)
        return {
          ...state,
          items: resultItems,
          nextToken,
          itemsByIds,
          loading: false,
          fetched: true,
        }
      }
      const itemsByIds = {}
      items.forEach((e, index) => {
        itemsByIds[e.id] = index
      })
      return { ...state, items, nextToken, itemsByIds, loading: false, fetched: true }
    }
    case actions.LIST_FAIL:
      return { ...state, errors: action.payload, loading: false, fetched: true }
    case actions.GET_SUCCESS:
      return { ...state, item: action.payload, loading: false, fetched: true }
    case actions.GET_FAIL:
      return { ...state, errors: action.payload, loading: false }
    case actions.CREATE_SUCCESS: {
      const { item } = action.payload
      const { itemsByIds } = state
      const items = [...state.items]
      items.push(item)
      itemsByIds[item.id] = items.length
      return { ...state, item, items, loading: false, error: null, created: true }
    }
    case actions.CREATE_CUSTOMER_SUCCESS:
      return { ...state, item: action.payload, loading: false, error: null, created: true }
    case actions.CREATE_DRIVER_SUCCESS:
      return { ...state, item: action.payload, loading: false, error: null, created: true }
    case actions.CREATE_FAIL:
      return { ...state, errors: action.payload, loading: false }
    case actions.CREATE_DRIVER_FAIL:
      return { ...state, errors: action.payload, loading: false }
    case actions.CREATE_CUSTOMER_FAIL:
      return { ...state, errors: action.payload, loading: false }
    case actions.UPDATE_SUCCESS: {
      const { item } = action.payload
      const { itemsByIds } = state
      const items = [...state.items]
      if (items[itemsByIds[item.id]] !== undefined) {
        Object.keys(item).forEach(e => {
          items[itemsByIds[item.id]][e] = item[e]
        })
      }
      return { ...state, item, items, loading: false, error: null, created: true }
    }
    case actions.UPDATE_FAIL:
      return { ...state, errors: action.payload, loading: false }
    case actions.REMOVE_SUCCESS: {
      const { items: itemIds } = action.payload
      const items = state.items.filter(e => !itemIds.includes(e.id))
      const itemsByIds = {}
      items.forEach((e, index) => {
        itemsByIds[e.id] = index
      })
      return { ...state, items, itemsByIds, loading: false, fetched: true }
    }
    case actions.REMOVE_FAIL:
      return { ...state, errors: action.payload, loading: false }
    case actions.CREATE_PURCHASE_ORDER_SUCCESS: {
      return { ...state, item: action.payload, loading: false, error: null, created: true }
    }
    default:
      return state
  }
}

export default crudReducer
