import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { list, create, update, setGroup, setStatus } from 'services/users'
import { Log } from 'utils'
import actions from './actions'

export function* LIST() {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: true, created: false, errors: null },
    })

    const users = yield call(list)

    yield put({
      type: actions.LIST_SUCCESS,
      payload: users,
    })
  } catch (error) {
    yield put({
      type: actions.LIST_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al obtener los datos.',
    })
  }
}

export function* GET({ id }) {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    const { users } = yield select()
    if (users.itemsByIds[id] === undefined) {
      const errors = 'USER_DOES_NOT_EXISTS'
      yield put({
        type: actions.GET_FAIL,
        payload: errors,
      })
    } else {
      const item = users.items[users.itemsByIds[id]]
      yield put({
        type: actions.GET_SUCCESS,
        payload: item,
      })
    }
    // const awsUser = yield call(get, { id })
  } catch (error) {
    yield put({
      type: actions.GET_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al obtener los datos.',
    })
  }
}

export function* CREATE({ payload }) {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    const item = yield call(create, payload)

    yield put({
      type: actions.CREATE_SUCCESS,
      payload: item,
    })
  } catch (error) {
    yield put({
      type: actions.CREATE_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al crear.',
    })
  }
}

export function* SET_GROUP({ payload }) {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    yield call(setGroup, payload)

    yield put({
      type: actions.SET_GROUP_SUCCESS,
      payload,
    })
  } catch (error) {
    yield put({
      type: actions.SET_GROUP_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al modificar el grupo.',
    })
  }
}

export function* UPDATE({ payload }) {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    const item = yield call(update, { payload })

    yield put({
      type: actions.UPDATE_SUCCESS,
      payload: item,
    })
  } catch (error) {
    yield put({
      type: actions.UPDATE_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al actualizar.',
    })
  }
}

export function* RESET() {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: { fetched: false, item: null },
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export function* SET_STATUS({ payload }) {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: true },
    })
    const { enable, items: itemsIds } = payload
    const {
      users: { items, itemsByIds },
    } = yield select()

    for (let index = 0; index < itemsIds.length; index += 1) {
      yield call(setStatus, { username: items[itemsByIds[itemsIds[index]]].email, enable })
    }

    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    })
    // yield put({
    //   type: actions.SET_STATUS_SUCCESS,
    //   payload,
    // })
  } catch (error) {
    yield put({
      type: actions.SET_STATUS_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al modificar el estado.',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.GET, GET),
    takeEvery(actions.RESET, RESET),
    takeEvery(actions.SET_GROUP, SET_GROUP),
    takeEvery(actions.SET_STATUS, SET_STATUS),
  ])
}
