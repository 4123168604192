import { all, takeEvery, put, select, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { create } from 'services/cruds'
import { Log } from 'utils'
import actions from './actions'

export function* LIST({ items }) {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: true, created: false },
    })

    yield put({
      type: actions.LIST_SUCCESS,
      payload: items,
    })
  } catch (error) {
    yield put({
      type: actions.LIST_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al obtener los datos.',
    })
  }
}

export function* PROCESS() {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: true, created: false },
    })

    const {
      auth: { customerId },
      loadDeliveries: { items },
    } = yield select()

    const keysToRemove = ['city', 'key']

    yield all(
      items.map(e => {
        const payload = {
          ...e,
          customerId,
          status: 'DRAFT',
          driverId: 'withoutdriver@gmail.com',
        }
        keysToRemove.forEach(r => {
          delete payload[r]
        })
        return call(create, { name: 'order', payload })
      }),
    )

    notification.success({
      message: 'Ok',
      description: 'Sus órdenes han sido cargadas satisfactoriamente.',
    })

    yield put({
      type: actions.SET_STATE,
      payload: { loading: false, created: true, items: [] },
    })
  } catch (error) {
    yield put({
      type: actions.LIST_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al procesar los datos.',
    })
  }
}

export function* RESET() {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: { fetched: false, item: null, items: [] },
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export function* CLEAR() {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: true },
    })
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false, items: [] },
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al limpiar los datos.',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.RESET, RESET),
    takeEvery(actions.PROCESS, PROCESS),
    takeEvery(actions.CLEAR, CLEAR),
  ])
}
