import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import { login, currentAccount, logout } from 'services/auth'
import listCustomers from 'services/customers'
import { Log } from 'utils'
import actions from './actions'

export function* LOGIN({ payload }) {
  try {
    const { email, password } = payload

    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const success = yield call(login, email, password)
    yield put({
      type: 'auth/LOAD_CURRENT_ACCOUNT',
    })
    if (success) {
      yield history.push('/')
      notification.success({
        message: 'Ingresado',
        description: 'Autenticación satistfactoria.',
      })
    }
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al autenticar.',
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  try {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield call(currentAccount)
    if (response) {
      const {
        attributes: { sub: id, email, name },
        groups,
      } = response

      const customers = yield call(listCustomers)
      const { id: customerId, name: customerName, prefix } = customers[0]

      yield put({
        type: 'auth/SET_STATE',
        payload: {
          id,
          name,
          email,
          groups,
          prefix,
          customerId,
          customerName,
          authorized: true,
        },
      })
    }
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al cargar la cuenta.',
    })
  }
}

export function* LOGOUT() {
  try {
    yield call(logout)
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        id: '',
        name: '',
        role: '',
        email: '',
        groups: [],
        customerId: null,
        customerName: '',
        authorized: false,
        loading: false,
      },
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
