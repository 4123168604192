import React from 'react'
import style from './style.module.scss'

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <a
          href="/dashboard/alpha"
          // target="_blank"
          rel="noopener noreferrer"
          className={style.logo}
        >
          QiWENTY
          <span />
        </a>
        <br />
        <p className="mb-0">© 2020 Qiwenty | Todos los derechos reservados.</p>
      </div>
    </div>
  )
}

export default Footer
