import { Auth } from 'aws-amplify'
import { notification } from 'antd'
import { Log } from 'utils'

export async function login(email, password) {
  return Auth.signIn(email, password)
    .then(user => {
      //  Auth.changePassword(user, 'ABCD123456', 'ABCD123456');
      //   if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      //     // const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
      //     Auth.completeNewPassword(
      //         user,               // the Cognito User Object
      //         'ABCD123456',       // the new password
      //         // OPTIONAL, the required attributes
      //         {
      //           email: 'user6@gmail.com',
      //           phone_number: '+1234567890',
      //         name: 'User 6',

      //         }
      //     ).then(_user => {
      //         // at this time the user is logged in if no MFA required
      //         console.log(_user);
      //     }).catch(e => {
      //       console.log(e);
      //     });
      // } else {
      //     // other situations
      // }
      return user
    })
    .catch(error => {
      error = translateError(error)

      Log(error)

      notification.warning({
        message: error.name,
        description: error.message,
      })
    })
}

// TODO: Implement...
export const createUser = async ({ name, email, password, groups }) => {
  return Auth.signUp({
    username: email,
    password,
    attributes: {
      email, // optional
      // phone_number: '+1234567890', // optional - E.164 number convention
      // other custom attributes
      name,
    },
    clientMetadata: {
      groups,
    },
  })
    .then(user => {
      return user
    })
    .catch(error => {
      Log(error)

      notification.warning({
        message: error.name,
        description: error.message,
      })
    })
}

export async function currentAccount() {
  return new Promise(resolve => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        const {
          attributes,
          signInUserSession: {
            accessToken: {
              payload: { 'cognito:groups': groups },
            },
          },
        } = user
        resolve({ attributes, groups })
      })
      .catch(() => {
        // notification.warning({
        //   message: error.name,
        //   description: error.message,
        // })
        resolve(false)
      })
  })
}

export async function logout() {
  return new Promise((resolve, reject) => {
    Auth.signOut()
      .then(() => {
        resolve(true)
      })
      .catch(error => {
        Log(error)

        notification.warning({
          message: error.name,
          description: error.message,
        })
        reject()
      })
  })
}
function translateError(error) {
  let { name } = error
  let { message } = error
  switch (error.name) {
    case 'NotAuthorizedException':
      name = 'Error de Acceso no Autorizado'
      break
    case 'UserNotFoundException':
      name = 'Error de Usuario no Encontrado'
      break
    default:
  }
  switch (error.message) {
    case 'Incorrect username or password.':
      message = 'Usuario o contraseña incorrectos.'
      break
    case 'User does not exist.':
      message = 'El usuario no existe.'
      break
    default:
  }
  return { name, message }
}
