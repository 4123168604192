const actions = {
  SET_STATE: 'loadDeliveries/SET_STATE',
  RESET: 'loadDeliveries/RESET',

  LIST: 'loadDeliveries/LIST',
  LIST_SUCCESS: 'loadDeliveries/LIST_SUCCESS',
  LIST_FAIL: 'loadDeliveries/LIST_FAIL',

  PROCESS: 'loadDeliveries/PROCESS',
  CLEAR: 'loadDeliveries/CLEAR',
}

export default actions
