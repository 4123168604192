import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { create } from 'services/cruds'
import assignOrders from 'services/assignorders'
import moment from 'moment'
import crudActions from 'redux/crud/actions'
import { Log } from 'utils'
import actions from './actions'

export function* ADD({ payload }) {
  try {
    const {
      route: { items: orders, itemsByIds },
    } = yield select()
    const { id } = payload

    const item = orders[itemsByIds[id]]

    yield put({
      name: 'assignRoutes',
      type: actions.ADD_SUCCESS,
      payload: { item },
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export function* REMOVE({ payload }) {
  try {
    yield put({
      name: 'assignRoutes',
      type: actions.REMOVE_SUCCESS,
      payload,
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export function* ADD_LIST({ payload }) {
  try {
    const {
      route: { items: orders, itemsByIds },
    } = yield select()
    const { ids } = payload

    const items = ids.map(e => orders[itemsByIds[e]])

    yield put({
      name: 'assignRoutes',
      type: actions.ADD_LIST_SUCCESS,
      payload: { items },
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export function* ASSIGN({ payload }) {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    const {
      assignOrders: { driverId },
      route: { items: orders, itemsByIds },
    } = yield select()

    const { ids } = payload

    const date = moment()
    const routeData = {
      name: date.format('YYYYMMDDHHmmss'),
      status: 'DRAFT',
      date,
      driverId,
    }

    const { item: route } = yield call(create, { name: 'route', payload: routeData })

    //   id: ID!
    // name: String!
    // status: String!
    // date: AWSDateTime!

    // driverId: ID!
    // driver: Driver @connection(fields: ["driverId"])

    // orders: [Order] @connection(name: "RouteOrders")

    yield all(
      ids.map((e, i) => {
        const order = orders[itemsByIds[e]]
        const { id, name, customerId, destinyDate } = order
        return call(assignOrders, {
          driverId,
          id,
          destinyDate,
          status: 'ASSIGNED',
          customerId,
          name,
          order: i,
          orderRouteId: route.id,
        })
      }),
    )

    const tmpOrders = orders.filter(e => !ids.includes(e.id))

    yield put({
      name: 'route',
      type: crudActions.LIST_SUCCESS,
      payload: tmpOrders,
    })

    yield put({
      type: actions.SET_STATE,
      payload: { loading: false, items: [], itemsByIds: {} },
    })

    notification.success({
      message: 'Info',
      description: `La ruta ${route.name} se ha creado correctamente.`,
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al crear una ruta.',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ASSIGN, ASSIGN),
    takeEvery(actions.ADD_LIST, ADD_LIST),
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.REMOVE, REMOVE),
  ])
}
