import { getCityId, Log } from 'utils'

const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY
const googleMapsClient = require('@google/maps').createClient({
  key: googleMapsKey,
  Promise,
})

export default async ({ id, cityId, address }) => {
  try {
    const city = getCityId(cityId)
    const geoResult = await googleMapsClient
      .geocode({ address: `${address}, ${city}, Chile` })
      .asPromise()
    if (geoResult.json.status === 'OK') {
      const tmpAddress = geoResult.json.results[0]
      const {
        // eslint-disable-next-line camelcase
        place_id,
        geometry: {
          location: { lat, lng },
        },
      } = tmpAddress
      return { id, placeId: place_id, lat, lng }
    }

    return { id }
  } catch (error) {
    Log(error)
    return { id }
  }
}
