const QRCode = require('qrcode')

const opts = {
  errorCorrectionLevel: 'H',
  type: 'image/jpeg',
  quality: 0.3,
  margin: 1,
  // color: {
  //   dark: '#010599FF',
  //   light: '#FFBF60FF',
  // },
}

export default async ({ id }) => {
  // destinyDate, cityId, address, customerName
  // console.log(JSON.stringify(id))

  // const tmpData = {
  //   id,
  //   destinyDate,
  //   cityId,
  //   address,
  //   customerName,
  // }

  // JSON.stringify(tmpData)
  const image = await QRCode.toDataURL(id, opts)
  // console.log(image);
  return { id, image }
}
