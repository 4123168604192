import actions from './actions'

const initialState = {
  loading: false,
  items: [],
  itemsByIds: {},
}

const assignRoutesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.ADD_LIST_SUCCESS: {
      const { items } = action.payload
      const itemsByIds = {}
      items.forEach((e, i) => {
        itemsByIds[e.id] = i
      })
      return { ...state, items, itemsByIds }
    }
    case actions.ADD_SUCCESS: {
      const { item } = action.payload
      const { items, itemsByIds } = state
      itemsByIds[item.id] = items.length
      const tmpItems = [...items, item]
      return { ...state, items: tmpItems, itemsByIds }
    }
    case actions.REMOVE_SUCCESS: {
      const { id } = action.payload
      const items = [...state.items].filter(e => e.id !== id)
      const itemsByIds = {}
      items.forEach((e, i) => {
        itemsByIds[e.id] = i
      })
      return { ...state, items, itemsByIds }
    }
    default:
      return state
  }
}

export default assignRoutesReducer
