import { API, graphqlOperation } from 'aws-amplify'
import { Log } from 'utils'

const listCustomers = /* GraphQL */ `
  query listCustomers {
    listCustomers {
      items {
        id
        name
        prefix
      }
    }
  }
`

export default async () => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(listCustomers))
      .then(({ data }) => {
        const { items } = data.listCustomers
        resolve(items)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}
