import actions from './actions'

const initialState = {
  items: [],
  itemsByIds: {},
  item: null,
  errors: null,
  loading: false,
  created: false,
  fetched: false,
}

const crudReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    // case actions.GET:
    //   return { ...state, loading: true, error: null }
    case actions.LIST_SUCCESS: {
      const items = action.payload
      const itemsByIds = {}
      items.forEach((e, index) => {
        itemsByIds[e.sub] = index
      })
      return { ...state, items, itemsByIds, loading: false, fetched: true }
    }
    case actions.LIST_FAIL:
      return { ...state, errors: action.payload, loading: false }
    case actions.GET_SUCCESS:
      return { ...state, item: action.payload, loading: false, fetched: true }
    case actions.GET_FAIL:
      return { ...state, errors: action.payload, loading: false }
    case actions.CREATE_SUCCESS:
      return { ...state, item: action.payload, loading: false, error: null, created: true }
    case actions.CREATE_FAIL:
      return { ...state, errors: action.payload, loading: false }
    case actions.UPDATE_SUCCESS:
      return { ...state, item: action.payload, loading: false, error: null, created: true }
    case actions.UPDATE_FAIL:
      return { ...state, errors: action.payload, loading: false }
    case actions.SET_GROUP_SUCCESS: {
      const { item, checked, group } = action.payload
      const { sub: userId } = item
      const { items, itemsByIds } = state
      if (checked) {
        items[itemsByIds[userId]].groups = [...items[itemsByIds[userId]].groups, group]
      } else {
        let { groups } = items[itemsByIds[userId]]
        groups = groups.filter(e => {
          return e !== group
        })
        items[itemsByIds[userId]].groups = groups
      }
      return { ...state, items, loading: false, error: null }
    }
    default:
      return state
  }
}

export default crudReducer
