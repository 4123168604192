import { API, graphqlOperation } from 'aws-amplify'
import { capitalize, Log } from 'utils'

const graphqlList = (modelName, fields) => {
  const fieldNames = fields.map(({ name, views }) => {
    if (views && !views.includes(1)) {
      return ''
    }
    return name
  })
  const list = `
    query ${modelName}($limit: Int) {
      ${modelName}(limit: $limit) {
        nextToken
        items {
          id ${fieldNames.join(' ')}
        }
				nextToken
      }
    }
  `
  return list
}

export const list = async ({ name, fields }) => {
  return new Promise((resolve, reject) => {
    const queryName = `list${capitalize(name)}s`
    const graphqlQuery = graphqlList(queryName, fields)

    API.graphql(graphqlOperation(graphqlQuery, { limit: 200 }))
      .then(({ data }) => {
        const result = data[queryName]
        resolve(result)
      })
      .catch(error => {
        Log(error)

        reject(error)
      })
  })
}

const graphqlGet = (modelName, fields) => {
  const fieldNames = fields.map(({ name, type, model }) => {
    if (type === 'relational') {
      if (model === 'evidences') return `${name} { items { id name images createdAt }}`
      return `${model} { id }`
    }
    // if (views && !views.includes(2)) {
    //   return ''
    // }
    return name
  })
  const get = `
    query ${modelName}($id: ID!) {
      ${modelName}(id: $id) {
        id ${fieldNames.join(' ')}
      }
    }
  `
  return get
}

export const get = async ({ name, fields, id }) => {
  return new Promise((resolve, reject) => {
    const queryName = `get${capitalize(name)}`
    const graphqlQuery = graphqlGet(queryName, fields)

    API.graphql(graphqlOperation(graphqlQuery, { id }))
      .then(({ data }) => {
        const item = data[queryName]
        resolve(item)
      })
      .catch(error => {
        Log(error)

        reject(error)
      })
  })
}

// TODO: improve create evidence.
// const graphqlGetOrder = (modelName, fields) => {
//   const fieldNames = fields.map(({ name, views }) => {
//     if (views && !views.includes(2)) {
//       return ''
//     }
//     return name
//   })
//   const getOrder = `
//     query ${modelName}($id: ID!) {
//       ${modelName}(id: $id) {
//         id ${fieldNames.join(' ')} evidence { id }
//       }
//     }
//   `
//   return getOrder
// }

// export const getOrder = async ({ name, fields, id }) => {
//   return new Promise((resolve, reject) => {
//     const queryName = `get${capitalize(name)}`
//     const graphqlQuery = graphqlGetOrder(queryName, fields)

//     API.graphql(graphqlOperation(graphqlQuery, { id }))
//       .then(({ data }) => {
//         const item = data[queryName]
//         resolve(item)
//       })
//       .catch(error => {
//         reject(error)
//       })
//   })
// }

const graphqlCreate = (modelName, model) => {
  const fieldNames = Object.keys(model)
  // const fieldNames = field.filter(e => e !== 'orderEvidenceId')
  const create = `
    mutation ${modelName} ($input: ${capitalize(modelName)}Input!) {
      ${modelName}(input: $input) {
        id ${fieldNames.join(' ')}
      }
    }
  `
  return create
}

export const create = async ({ name, payload }) => {
  return new Promise((resolve, reject) => {
    const queryName = `create${capitalize(name)}`
    const graphqlQuery = graphqlCreate(queryName, payload)
    API.graphql(graphqlOperation(graphqlQuery, { input: payload }))
      .then(({ data }) => {
        const item = data[queryName]
        resolve({ item })
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const graphqlUpdate = (modelName, model) => {
  const fieldNames = Object.keys(model)
  const update = `
    mutation ${modelName} ($input: ${capitalize(modelName)}Input!) {
      ${modelName}(input: $input) {
        id ${fieldNames.filter(e => !['orderPurchaseOrderId'].includes(e)).join(' ')}
      }
    }
  `
  return update
}

export const update = async ({ name, payload }) => {
  return new Promise((resolve, reject) => {
    const queryName = `update${capitalize(name)}`
    const graphqlQuery = graphqlUpdate(queryName, payload)

    API.graphql(graphqlOperation(graphqlQuery, { input: payload }))
      .then(({ data }) => {
        const item = data[queryName]
        resolve({ item })
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const graphqlCreateQiwentyCustomer = (modelName, model) => {
  const fieldNames = Object.keys(model)
  const createQiwentyCustomer = `
    mutation ${modelName} ($input: ${capitalize(modelName)}Input!) {
      ${modelName}(input: $input) {
        id ${fieldNames.join(' ')}
      }
    }
  `
  return createQiwentyCustomer
}

export const createQiwentyCustomer = async ({ name, payload }) => {
  return new Promise((resolve, reject) => {
    const queryName = `create${capitalize(name)}`
    const graphqlQuery = graphqlCreateQiwentyCustomer(queryName, payload)
    API.graphql(graphqlOperation(graphqlQuery, { input: payload }))
      .then(({ data }) => {
        const item = data[queryName]
        resolve({ item })
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const graphqlCreateQiwentyDriver = (modelName, model) => {
  const fieldNames = Object.keys(model)
  const createQiwentyDriver = `
    mutation ${modelName} ($input: ${capitalize(modelName)}Input!) {
      ${modelName}(input: $input) {
        id ${fieldNames.join(' ')}
      }
    }
  `
  return createQiwentyDriver
}

export const createQiwentyDriver = async ({ name, payload }) => {
  return new Promise((resolve, reject) => {
    const queryName = `create${capitalize(name)}`
    const graphqlQuery = graphqlCreateQiwentyDriver(queryName, payload)
    API.graphql(graphqlOperation(graphqlQuery, { input: payload }))
      .then(({ data }) => {
        const item = data[queryName]
        resolve({ item })
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

// TODO: improve create evidence.
// const createEvidenceGraphQl = /* GraphQL */ `
//     mutation createEvent ($input: CreateEventInput!, $input2: UpdateOrderInput!) {
//       createEvent (input: $input) {
//         id
//       }
//       updateOrder (input: $input2) {
//         id
//       }
//     }
//   `

// export const createEvidence = async ({ payload }) => {
//   return new Promise((resolve, reject) => {
//     // const queryName = `create${capitalize(name)}`

//     API.graphql(graphqlOperation(createEvidenceGraphQl, { input: data, input2: data2 }))
//       .then(({ data }) => {
//         const item = data.createEvent
//         resolve({ item })
//       })
//       .catch(error => {
//         reject(error)
//       })
//   })
// }

// CUSTOMERS
const warehouses = /* GraphQL */ `
  query byCustomer($id: ID!) {
    getCustomer(id: $id) {
      warehouses {
        items {
          id
          name
          fixedPrice
          price
          address
          default
          prices {
            items {
              id
              price
              cityId
            }
          }
        }
      }
    }
  }
`

export const warehousesByCustomers = async ({ id }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(warehouses, { id }))
      .then(({ data }) => {
        const result = data.getCustomer.warehouses
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const orders = /* GraphQL */ `
  query byCustomer($id: ID!) {
    getCustomer(id: $id) {
      orders {
        items {
          id
          name
          pickupDate
          destinyDate
          deliveryDate
          price
          address
          status
          referenceNumber
          customerName
          customerId
          cellphone
          productDescription
          city {
            id
            name
          }
        }
      }
    }
  }
`

const ordersItems = `
      items {
        id
        name
        pickupDate
        destinyDate
        deliveryDate
        price
        address
        status
        driverId
        lat
        lng
        placeId
        city {
          id
          name
        }
        driver {
          id
          name
        }
        warehouse {
          id
          name
          price
          fixedPrice
          paymentAmount
        }
        customer {
          id
          name
        }
        referenceNumber
        customerName
        customerId
        cellphone
        productDescription
      }`

const byStatus = /* GraphQL */ `
  query byStatus($status: String!, $nextToken: String) {
		byStatusName(status: $status, nextToken: $nextToken) {
			${ordersItems}
      nextToken
    }
  }
`
// TODO: check if this method is used
const byCustomerByStatus = /* GraphQL */ `
  query byCustomer($id: ID!, $status: String) {
    getCustomer(id: $id) {
      id
      email
      ordersByStatus(status: { eq: $status }) {
        items {
          id
          name
          pickupDate
          destinyDate
          deliveryDate
          price
          address
          cityId
          status
          driverId
          city {
            id
            name
          }
          driver {
            id
            name
          }
          referenceNumber
          customerName
          customerId
          cellphone
          productDescription
        }
      }
    }
  }
`

export const ordersByCustomers = async ({ id }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(orders, { id }))
      .then(({ data }) => {
        const { items } = data.getCustomer.orders
        resolve(items)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

// TODO: check to remove
export const ordersByStatusOld = async ({ status, nextToken }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byStatus, { status, nextToken }))
      .then(({ data }) => {
        const result = data.byStatusName
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

export const ordersByCustomerByStatus = async ({ id, status }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byCustomerByStatus, { id, status }))
      .then(({ data }) => {
        const { items } = data.getCustomer.ordersByStatus
        resolve(items)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const byWarehouse = /* GraphQL */ `
  query byCustomer($id: ID!) {
    getWarehouse(id: $id) {
      id
      prices {
        items {
          id
          price
          city {
            id
            name
          }
        }
      }
    }
  }
`

export const pricesByWarehouse = async ({ id }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byWarehouse, { id }))
      .then(({ data }) => {
        const { items: tmpItems, nextToken } = data.getWarehouse.prices
        tmpItems.map(e => {
          e.city = e.city.name
          return e
        })
        resolve({ items: tmpItems, nextToken })
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const createPriceGraphql = /* GraphQL */ `
  mutation createPrice($input: CreatePriceInput!) {
    createPrice(input: $input) {
      id
      price
      cityId
      warehouseId
      city {
        id
        name
      }
    }
  }
`

export const createCity = async ({ payload }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(createPriceGraphql, { input: payload }))
      .then(({ data }) => {
        const item = data.createPrice
        item.city = item.city.name
        resolve({ item })
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const graphqlRemove = (modelName, model) => {
  const fieldNames = Object.keys(model)
  const remove = `
    mutation ${modelName} ($input: ${capitalize(modelName)}Input!) {
      ${modelName}(input: $input) {
        id ${fieldNames.join(' ')}
      }
    }
  `
  return remove
}

export const remove = async ({ name, payload }) => {
  return new Promise((resolve, reject) => {
    const queryName = `delete${capitalize(name)}`
    const graphqlQuery = graphqlRemove(queryName, payload)
    API.graphql(graphqlOperation(graphqlQuery, { input: payload }))
      .then(({ data }) => {
        const item = data[queryName]
        resolve({ item })
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const byOrder = /* GraphQL */ `
  query listEvidences($id: ID!) {
    listEvidences(filter: { orderId: { eq: $id } }) {
      items {
        createdAt
        id
        images
        name
        orderId
        order {
          id
          name
          driverId
        }
        updatedAt
      }
    }
  }
`

export const evidencesByOrder = async ({ id }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byOrder, { id }))
      .then(({ data }) => {
        const { items } = data.listEvidences
        items.map(e => {
          e.driverId = e.order.driverId
          return e
        })
        resolve(items)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const byFilter = /* GraphQL */ `
  query listEvidences($nextToken: String) {
    listEvidences(nextToken: $nextToken) {
      items {
        createdAt
        id
        images
        name
        orderId
        order {
          id
          name
          driverId
          driver {
            id
            name
          }
        }
        updatedAt
      }
      nextToken
    }
  }
`

export const evidencesByFilter = async ({ nextToken }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byFilter, { nextToken }))
      .then(({ data }) => {
        const { items, nextToken: tmpNextToken } = data.listEvidences
        items
          .filter(o => o.order && o.order.driver)
          .map(e => {
            e.driver = e.order.driver
            return e
          })

        resolve({ items, nextToken: tmpNextToken })
      })
      .catch(error => {
        console.log(error)
        Log(error)
        reject(error)
      })
  })
}

const byOrderName = /* GraphQL */ `
  query byOrderName($name: String!, $nextToken: String) {
    # TODO: Include other status, payed for example.
    byStatusName(status: "DELIVERED", name: { eq: $name }, nextToken: $nextToken) {
      items {
        evidences {
          items {
            createdAt
            id
            images
            name
            orderId
            order {
              id
              name
              driverId
              driver {
                id
                name
              }
            }
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`

export const evidencesByOrderName = async ({ name, nextToken }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byOrderName, { name, nextToken }))
      .then(({ data }) => {
        const { items, nextToken: tmpNextToken } = data.byStatusName
        if (items && items.length > 0) {
          const { items: evidenceItems } = items[0].evidences
          evidenceItems.map(e => {
            e.driver = e.order.driver
            return e
          })
          resolve({ items: evidenceItems, nextToken: tmpNextToken })
        } else {
          resolve({ items: [], nextToken: tmpNextToken })
        }
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const ByName = /* GraphQL */ `
  query ByName($name: String) {
    listOrders(filter: { name: { contains: $name } }) {
      items {
        id
        name
        pickupDate
        destinyDate
        deliveryDate
        price
        address
        status
        driverId
        city {
          id
          name
        }
        driver {
          id
          name
        }
        referenceNumber
        customerName
        customerId
        cellphone
        productDescription
      }
    }
  }
`

export const ordersByName = async ({ name }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(ByName, { name }))
      .then(({ data }) => {
        const { items } = data.listOrders
        resolve(items)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const byStatusByName = /* GraphQL */ `
  query byStatusByName($name: String, $status: String) {
    listOrders(filter: { status: { eq: $status }, name: { contains: $name } }) {
      items {
        id
        name
        pickupDate
        destinyDate
        deliveryDate
        price
        address
        status
        driverId
        city {
          id
          name
        }
        driver {
          id
          name
        }
        referenceNumber
        customerName
        customerId
        cellphone
        productDescription
        customerId
      }
    }
  }
`

export const ordersByStatusByName = async ({ name, status }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byStatusByName, { name, status }))
      .then(({ data }) => {
        const { items } = data.listOrders
        resolve(items)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const byCustomerByName = /* GraphQL */ `
  query byCustomerByName($name: String, $customerId: ID!) {
    listOrders(filter: { customerId: { eq: $customerId }, name: { contains: $name } }) {
      items {
        id
        name
        pickupDate
        destinyDate
        deliveryDate
        price
        address
        status
        driverId
        city {
          id
          name
        }
        driver {
          id
          name
        }
        referenceNumber
        customerName
        customerId
        cellphone
        productDescription
        customerId
      }
    }
  }
`

export const ordersByCustomerByName = async ({ name, customerId }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byCustomerByName, { name, customerId }))
      .then(({ data }) => {
        const { items } = data.listOrders
        resolve(items)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const byCustomerByStatusByName = /* GraphQL */ `
  query byCustomerByStatusByName($name: String, $customerId: ID!, $status: String) {
    listOrders(
      filter: {
        customerId: { eq: $customerId }
        name: { contains: $name }
        status: { eq: $status }
      }
    ) {
      items {
        id
        name
        pickupDate
        destinyDate
        deliveryDate
        price
        address
        status
        driverId
        city {
          id
          name
        }
        driver {
          id
          name
        }
        referenceNumber
        customerName
        customerId
        cellphone
        productDescription
        customerId
      }
    }
  }
`

export const ordersByCustomerByStatusByName = async ({ name, customerId, status }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byCustomerByStatusByName, { name, customerId, status }))
      .then(({ data }) => {
        const { items } = data.listOrders
        resolve(items)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const byDate = /* GraphQL */ `
  query byDate($startDate: Date, $endDate: Date) {
    listOrders(filter: { destinyDate: { between: [$startDate, $endDate] } }) {
      items {
        id
        name
        pickupDate
        destinyDate
        deliveryDate
        price
        address
        status
        driverId
        city {
          id
          name
        }
        driver {
          id
          name
        }
        referenceNumber
        customerName
        customerId
        cellphone
        productDescription
      }
    }
  }
`

export const ordersByDate = async ({ destinyDate }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byDate, { destinyDate }))
      .then(({ data }) => {
        const { items } = data.listOrders
        resolve(items)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

// TODO: remove this method...
const byDateByStatus = /* GraphQL */ `
  query byDateByStatus($startDate: String, $endDate: String, $status: String, $nextToken: String) {
    listOrders(
      filter: {
        destinyDate: { between: [$startDate, $endDate] }
        status: { eq: $status }
        nextToken: $nextToken
      }
    ) {
      items {
        id
        name
        pickupDate
        destinyDate
        deliveryDate
        price
        address
        status
        driverId
        city {
          id
          name
        }
        driver {
          id
          name
        }
        warehouse {
          id
          name
          price
          fixedPrice
          paymentAmount
        }
        customer {
          id
          name
        }
        referenceNumber
        customerName
        customerId
        cellphone
        productDescription
      }
      nextToken
    }
  }
`

const queryOrdersByStatus = /* GraphQL */ `
  query ordersByStatusDate($status: String!, $nextToken: String) {
		ordersByStatusDate(status: $status, nextToken: $nextToken, sortDirection: DESC) {
			${ordersItems}
      nextToken
    }
  }
`

export const ordersByStatus = async ({ status, nextToken }) => {
  return new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(queryOrdersByStatus, {
        status,
        nextToken,
      }),
    )
      .then(({ data }) => {
        const result = data.ordersByStatusDate
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

export const ordersByDateByStatus = async ({ startDate, endDate, status, nextToken }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byDateByStatus, { startDate, endDate, status, nextToken }))
      .then(({ data }) => {
        const result = data.listOrders
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const queryOrdersByStatusDate = /* GraphQL */ `
  query ordersByStatusDate($status: String!, $nextToken: String, $destinyDateStart: String, $destinyDateEnd: String) {
		ordersByStatusDate(status: $status, nextToken: $nextToken, sortDirection: DESC, destinyDate: {
			between: [ $destinyDateStart, $destinyDateEnd ]
		}) {
			${ordersItems}
      nextToken
    }
  }
`

export const ordersByStatusDate = async ({
  status,
  nextToken,
  destinyDateStart,
  destinyDateEnd,
}) => {
  return new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(queryOrdersByStatusDate, {
        status,
        nextToken,
        destinyDateStart,
        destinyDateEnd,
      }),
    )
      .then(({ data }) => {
        const result = data.ordersByStatusDate
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const queryOrdersByDriversStatus = /* GraphQL */ `
  query orderByDriverStatus($driverId: ID!, $status: String!, $nextToken: String) {
		byDriverStatusDate(driverId: $driverId, nextToken: $nextToken, sortDirection: DESC, statusDestinyDate: {
			beginsWith: { status: $status }
		}) {
			${ordersItems}
      nextToken
    }
  }
`

export const ordersByDriversStatus = async ({ driverId, status, nextToken }) => {
  return new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(queryOrdersByDriversStatus, {
        driverId,
        status,
        nextToken,
      }),
    )
      .then(({ data }) => {
        const result = data.byDriverStatusDate
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const queryOrdersByDriversStatusDate = /* GraphQL */ `
  query ordersByDriversStatusDate($driverId: ID!, $status: String!, $nextToken: String, $destinyDateStart: String, $destinyDateEnd: String) {
		byDriverStatusDate(driverId: $driverId, nextToken: $nextToken, sortDirection: DESC, statusDestinyDate: {
			between: [
				{status: $status, destinyDate: $destinyDateStart},
				{status: $status, destinyDate: $destinyDateEnd},
			]
		}) {
			${ordersItems}
      nextToken
    }
  }
`

export const ordersByDriversStatusDate = async ({
  driverId,
  status,
  nextToken,
  destinyDateStart,
  destinyDateEnd,
}) => {
  return new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(queryOrdersByDriversStatusDate, {
        driverId,
        status,
        nextToken,
        destinyDateStart,
        destinyDateEnd,
      }),
    )
      .then(({ data }) => {
        const result = data.byDriverStatusDate
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const byDriver = /* GraphQL */ `
  query byDriver($driverId: ID!) {
    listOrders(filter: { driverId: { eq: $driverId } }) {
      items {
        id
        name
        pickupDate
        destinyDate
        deliveryDate
        price
        address
        status
        driverId
        city {
          id
          name
        }
        driver {
          id
          name
        }
        referenceNumber
        customerName
        customerId
        cellphone
        productDescription
      }
    }
  }
`

export const ordersByDriver = async ({ driverId }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byDriver, { driverId }))
      .then(({ data }) => {
        const { items } = data.listOrders
        resolve(items)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const byDriverByStatus = /* GraphQL */ `
  query byDriverByStatus($driverId: ID!, $status: String) {
    listOrders(filter: { driverId: { eq: $driverId }, status: { eq: $status } }) {
      items {
        id
        name
        pickupDate
        destinyDate
        deliveryDate
        price
        address
        status
        driverId
        city {
          id
          name
        }
        driver {
          id
          name
        }
        referenceNumber
        customerName
        customerId
        cellphone
        productDescription
      }
    }
  }
`

export const ordersByDriverByStatus = async ({ driverId, status }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byDriverByStatus, { driverId, status }))
      .then(({ data }) => {
        const { items } = data.listOrders
        resolve(items)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const ordersResultItems = `
  items {
    id
    name
    pickupDate
    destinyDate
    deliveryDate
    price
    address
    status
    driverId
    lat
    lng
    placeId
    city {
      id
      name
    }
    driver {
      id
      name
    }
    referenceNumber
    customerName
    printed
    customerId
    cellphone
    productDescription
  }
`

const byCustomerStatusName = /* GraphQL */ `
  query byCustomerStatusName($customerId: ID!, $status: String!, $name: String!) {
    byCustomerStatusName(customerId: $customerId, statusName: {eq: {status: $status, name: $name}} ) {
      ${ordersResultItems}
    }
  }
`
export const ordersByCustomerStatusName = async ({ customerId, status, name }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byCustomerStatusName, { customerId, status, name }))
      .then(({ data }) => {
        const result = data.byCustomerStatusName
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const byStatusName = /* GraphQL */ `
  query byStatusName($status: String!, $name: String!) {
    byStatusName(status: $status, name: {eq: $name}) {
      ${ordersResultItems}
			nextToken
    }
  }
`
export const ordersByStatusName = async ({ status, name }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byStatusName, { status, name }))
      .then(({ data }) => {
        const result = data.byStatusName
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const queryOrdersByCustomerStatus = /* GraphQL */ `
  query ordersByCustomerStatusDestinyDate($customerId: ID!, $status: String!, $nextToken: String) {
		ordersByCustomerStatusDestinyDate(
			customerId: $customerId, statusDestinyDate: { beginsWith: { status: $status }}, sortDirection: DESC, nextToken: $nextToken) {
      ${ordersResultItems}
			nextToken
    }
  }
`
export const ordersByCustomerStatus = async ({ customerId, status, nextToken }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(queryOrdersByCustomerStatus, { customerId, status, nextToken }))
      .then(({ data }) => {
        const result = data.ordersByCustomerStatusDestinyDate
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const purchaseOrdersResultItems = `
  items {
    id
    name
    date
    driverId
    driver { name }
    amount
    status
  }
`

const byPurchaseOrdersStatus = /* GraphQL */ `
  query byPurchaseOrderStatusDate($status: String!, $nextToken: String) {
    byStatusDate(status: $status, nextToken: $nextToken) {
      ${purchaseOrdersResultItems}
			nextToken
    }
  }
`

export const purchasesOrdersStatus = async ({ status, nextToken }) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byPurchaseOrdersStatus, { status, nextToken }))
      .then(({ data }) => {
        const result = data.byStatusDate
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const byPurchaseOrdersStatusDate = /* GraphQL */ `
  query byPurchaseOrdersStatus($status: String!, $startDate: String!, $endDate: String!, $nextToken:String) {
    byStatusDate(status: $status, date: {between: [
      $startDate,
      $endDate
    ]}, nextToken: $nextToken) {
      ${purchaseOrdersResultItems}
			nextToken
    }
  }
`

export const purchasesOrdersStatusDate = async payload => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byPurchaseOrdersStatusDate, payload))
      .then(({ data }) => {
        const result = data.byStatusDate
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const byPurchasesOrdersDriverStatus = /* GraphQL */ `
  query byPurchaseOrdersDriverStatus($driverId: ID!, $status: String!, $nextToken: String) {
    byPurchasesOrdersDriverStatusDate(driverId: $driverId, statusDate: {beginsWith: {status: $status} }, nextToken: $nextToken ) {
      ${purchaseOrdersResultItems}
			nextToken
    }
  }
`

export const purchasesOrdersDriverStatus = async payload => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byPurchasesOrdersDriverStatus, payload))
      .then(({ data }) => {
        const result = data.byPurchasesOrdersDriverStatusDate
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const byPurchasesOrdersDriverStatusDate = /* GraphQL */ `
  query byPurchasesOrdersDriverStatusDate($driverId: ID!, $status: String!, $startDate: String!, $endDate: String!, $nextToken: String) {
    byPurchasesOrdersDriverStatusDate(driverId: $driverId, statusDate: {between: [
      {status: $status, date: $startDate},
      {status: $status, date: $endDate}
    ]}, nextToken: $nextToken) {
      ${purchaseOrdersResultItems}
			nextToken
    }
  }
`

export const purchasesOrdersDriverStatusDate = async payload => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(byPurchasesOrdersDriverStatusDate, payload))
      .then(({ data }) => {
        const result = data.byPurchasesOrdersDriverStatusDate
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}

const getPurchaseOrder = /* GraphQL */ `
    query getPurchaseOrder ($purchaseOrderId: ID!, $nextToken: String) {
      getPurchaseOrder(id: $purchaseOrderId) {
        orders(nextToken: $nextToken) {
          ${ordersResultItems}
					nextToken
        }
      }
    }
  `
export const ordersFromPurchase = async payload => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(getPurchaseOrder, payload))
      .then(({ data }) => {
        const result = data.getPurchaseOrder.orders
        resolve(result)
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}
