import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { geolocation } from 'services'
import assignOrders from 'services/assignorders'
import { create, update } from 'services/cruds'
import moment from 'moment'
import { Log } from 'utils'
import { notification } from 'antd'
import actions from './actions'
import crudActions from '../crud/actions'

export function* SET_ORDER(payload) {
  try {
    yield put({
      type: actions.SET_STATE,
      payload,
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export function* SET_DRIVER(payload) {
  try {
    yield put({
      type: actions.SET_STATE,
      payload,
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export function* ASSIGN({ payload }) {
  try {
    yield put({
      type: crudActions.SET_STATE,
      payload: { loading: true },
    })
    const {
      assignOrders: { driverId },
      order: { items: orders, itemsByIds },
    } = yield select()
    const { ids } = payload

    yield all(
      ids.map(e => {
        const order = orders[itemsByIds[e]]
        const { id, name, customerId, destinyDate } = order
        return call(assignOrders, {
          driverId,
          id,
          destinyDate,
          status: 'ASSIGNED',
          customerId,
          name,
        })
      }),
    )

    const tmpOrders = orders.filter(e => !ids.includes(e.id))

    yield put({
      name: 'order',
      type: crudActions.LIST_SUCCESS,
      payload: tmpOrders,
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export function* UNASSIGN({ payload }) {
  try {
    yield put({
      type: crudActions.SET_STATE,
      payload: { loading: true },
    })
    const {
      order: { items: orders, itemsByIds },
    } = yield select()
    const { ids } = payload
    const driverId = 'withoutdriver@gmail.com'

    yield all(
      ids.map(e => {
        const order = orders[itemsByIds[e]]
        const { id, name, customerId, destinyDate } = order
        return call(assignOrders, {
          id,
          name,
          customerId,
          destinyDate,
          driverId,
          status: 'PROCESSED',
        })
      }),
    )

    const tmpOrders = orders.filter(e => !ids.includes(e.id))

    yield put({
      name: 'order',
      type: crudActions.LIST_SUCCESS,
      payload: tmpOrders,
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export function* DELIVERED({ payload }) {
  try {
    yield put({
      type: crudActions.SET_STATE,
      payload: { loading: true },
    })
    const {
      order: { items: orders, itemsByIds },
    } = yield select()
    const { ids, evidenceDescription } = payload
    yield all(
      ids.map(e => {
        const order = orders[itemsByIds[e]]
        const { id, name, customerId, destinyDate } = order
        const evidencePayload = {
          name: evidenceDescription,
          orderId: id,
          customerId,
        }
        return [
          call(assignOrders, {
            id,
            name,
            customerId,
            destinyDate,
            status: 'DELIVERED',
            deliveryDate: moment(),
          }),
          call(create, { name: 'evidence', payload: evidencePayload }),
        ]
      }),
    )
    yield put({
      name: 'order',
      type: crudActions.LIST_SUCCESS,
      payload: [...orders],
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export function* CHANGE_STATUS({ payload }) {
  try {
    yield put({
      name: 'order',
      type: crudActions.SET_STATE,
      payload: { loading: true },
    })
    const {
      order: { items: orders, itemsByIds },
    } = yield select()
    const { ids, status } = payload
    yield all(
      ids.map(e => {
        const order = orders[itemsByIds[e]]
        const { id, name, customerId, destinyDate } = order
        return call(assignOrders, { id, status, name, customerId, destinyDate })
      }),
    )

    const tmpOrders = orders.filter(e => !ids.includes(e.id))

    yield put({
      name: 'order',
      type: crudActions.LIST_SUCCESS,
      payload: tmpOrders,
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export function* LOCATE({ payload }) {
  try {
    yield put({
      name: 'order',
      type: crudActions.SET_STATE,
      payload: { loading: true },
    })

    const { ids } = payload

    const state = yield select()
    const { items, itemsByIds } = state.order

    const geoLocations = yield all(
      ids.map(e => {
        const item = items[itemsByIds[e]]
        return call(geolocation, { id: e, cityId: item.city.id, address: item.address })
      }),
    )

    for (let i = 0; i < geoLocations.length; i += 1) {
      const tmpLocation = geoLocations[i]
      const item = items[itemsByIds[tmpLocation.id]]
      if (tmpLocation.placeId !== null) {
        item.placeId = tmpLocation.placeId
        item.lat = tmpLocation.lat
        item.lng = tmpLocation.lng
      }
    }

    yield all(
      ids.map(e => {
        const { placeId, lat, lng } = items[itemsByIds[e]]
        if (placeId !== null) {
          return call(update, {
            name: 'order',
            payload: {
              id: e,
              placeId,
              lat,
              lng,
            },
          })
        }
        return null
      }),
    )

    yield put({
      name: 'order',
      type: crudActions.SET_STATE,
      payload: { loading: false, items },
    })
  } catch (error) {
    // console.log(JSON.stringify(error))
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al generar la Geolocalización.',
    })

    yield put({
      name: 'order',
      type: crudActions.SET_STATE,
      payload: { loading: false },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_DRIVER, SET_DRIVER),
    takeEvery(actions.SET_ORDER, SET_ORDER),
    takeEvery(actions.ASSIGN, ASSIGN),
    takeEvery(actions.UNASSIGN, UNASSIGN),
    takeEvery(actions.DELIVERED, DELIVERED),
    takeEvery(actions.CHANGE_STATUS, CHANGE_STATUS),
    takeEvery(actions.LOCATE, LOCATE),
  ])
}
