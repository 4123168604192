const actions = {
  SET_STATE: 'assignroutes/SET_STATE',
  ASSIGN: 'assignroutes/ASSIGN',

  ADD: 'assignroutes/ADD',
  ADD_SUCCESS: 'assignroutes/ADD_SUCCESS',
  REMOVE: 'assignroutes/REMOVE',
  REMOVE_SUCCESS: 'assignroutes/REMOVE_SUCCESS',
  ADD_LIST: 'assignroutes/ADD_LIST',
  ADD_LIST_SUCCESS: 'assignroutes/ADD_LIST_SUCCESS',
}

export default actions
