import 'antd/lib/style/index.less' // antd core styles
import './components/kit-vendors/antd/themes/default.less' // default theme antd components
import './components/kit-vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
// import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import Amplify, { Auth } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import Router from './router'
import * as serviceWorker from './serviceWorker'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }
// import awsconfig from './aws-exports'

// middlewared
const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]

// You can get the current config object
const awsConfiguration = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL,

    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_S3_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT,
  },
  API: {
    graphql_endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    graphql_headers: async () => ({
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    }),
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET, // REQUIRED -  Amazon S3 bucket
      region: process.env.REACT_APP_S3_REGION, // 'XX-XXXX-X', // OPTIONAL -  Amazon service region
    },
  },
  endpoints: [
    {
      name: 'AdminQueries',
      endpoint: process.env.REACT_APP_ADMIN_QUERIES_ENDPOINT,
    },
  ],

  // aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_S3_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS', // API_KEY | AWS_IAM | OPENID_CONNECT | AMAZON_COGNITO_USER_POOLS
  // aws_appsync_apiKey: process.env.REACT_APP_GRAPHQL_API_KEY,
}

// Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
Amplify.configure(awsConfiguration)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducers(history), composeEnhancers(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }
