import { all } from 'redux-saga/effects'
import auth from './auth/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import crud from './crud/sagas'
import users from './users/sagas'
import assignOrders from './assignorders/sagas'
import assignRoutes from './assignroutes/sagas'
import loadDeliveries from './loadDeliveries/sagas'
import password from './password/sagas'

export default function* rootSaga() {
  yield all([
    auth(),
    menu(),
    settings(),
    crud(),
    users(),
    assignOrders(),
    assignRoutes(),
    loadDeliveries(),
    password(),
  ])
}
