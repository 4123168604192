import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import * as models from 'models'
import * as qiwentyModels from 'qiwentymodels'
import auth from './auth/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import crudReducer from './crud/reducer'
import users from './users/reducer'
import assignOrders from './assignorders/reducer'
import assignRoutes from './assignroutes/reducer'
import loadDeliveries from './loadDeliveries/reducer'
import password from './password/reducer'

const wrapperReducer = (reducerFunction, reducerName) => {
  return (state, action) => {
    const { name } = action
    const isInitializationCall = state === undefined
    if (name !== reducerName && !isInitializationCall) return state

    return reducerFunction(state, action)
  }
}

export default history => {
  const crudModels = {}
  const listDeliveriesModels = {}
  Object.keys(models).forEach(e => {
    crudModels[models[e].name] = wrapperReducer(crudReducer, models[e].name)
  })

  Object.keys(qiwentyModels).forEach(e => {
    crudModels[`qiwenty${qiwentyModels[e].name}`] = wrapperReducer(
      crudReducer,
      `qiwenty${qiwentyModels[e].name}`,
    )
  })

  const localReducers = {
    router: connectRouter(history),
    auth,
    menu,
    users,
    settings,
    assignOrders,
    assignRoutes,
    loadDeliveries,
    password,
  }

  const reducers = { ...localReducers, ...crudModels, ...listDeliveriesModels }
  return combineReducers(reducers)
}
