import { Auth, API, graphqlOperation } from 'aws-amplify'
import { notification } from 'antd'
import { capitalize, Log } from 'utils'

export const list = async () => {
  const apiName = 'AdminQueries'
  const path = '/listUsersInGroup'
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
  }
  const groups = ['Admin', 'Qiwenty', 'Customer', 'Driver']
  const users = []
  const usersByIds = {}
  let count = 0

  for (let index = 0; index < groups.length; index += 1) {
    const g = groups[index]
    const myInit = {
      queryStringParameters: {
        groupname: g,
        limit: 50,
      },
      headers,
    }

    // eslint-disable-next-line no-await-in-loop
    const { Users } = await API.get(apiName, path, myInit)
    // eslint-disable-next-line no-loop-func
    Users.forEach(u => {
      const userPosition = usersByIds[u.Username]
      if (userPosition === undefined) {
        const values = { enabled: u.Enabled }
        const { Attributes } = u
        Attributes.forEach(({ Name, Value }) => {
          values[Name] = Value
        })

        values.groups = [g]
        users[count] = values
        usersByIds[u.Username] = count
        count += 1
      } else {
        users[userPosition].groups = [...users[userPosition].groups, g]
      }
    })
  }

  return users
}

export const get = async ({ id }) => {
  const apiName = 'AdminQueries'
  const path = '/getUser'
  const myInit = {
    queryStringParameters: {
      username: id,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  const User = await API.get(apiName, path, myInit)
  return User
}

export const create = async ({ name, email, password }) => {
  return Auth.signUp({
    username: email,
    password,
    attributes: {
      email, // optional
      // phone_number: '+1234567890', // optional - E.164 number convention
      // other custom attributes
      name,
    },
  })
    .then(user => {
      return user
    })
    .catch(error => {
      Log(error)

      notification.warning({
        message: error.name,
        description: error.message,
      })
    })
}

export const setGroup = async ({ item, group, checked }) => {
  const apiName = 'AdminQueries'
  let path = '/addUserToGroup'
  if (!checked) {
    path = '/removeUserFromGroup'
  }
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
  }

  const myInit = {
    body: {
      username: item.email,
      groupname: group,
    },
    headers,
  }

  await API.post(apiName, path, myInit)
}

export const setStatus = async ({ username, enable }) => {
  const apiName = 'AdminQueries'
  const path = enable ? '/enableUser' : '/disableUser'

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
  }

  const myInit = {
    body: {
      username,
    },
    headers,
  }

  await API.post(apiName, path, myInit)
}

const graphqlUpdate = (modelName, model) => {
  const fieldNames = Object.keys(model)
  const update = `
    mutation ${modelName} ($input: ${capitalize(modelName)}Input!) {
      ${modelName}(input: $input) {
        id ${fieldNames.join(' ')}
      }
    }
  `
  return update
}

export const update = async ({ name, payload }) => {
  return new Promise((resolve, reject) => {
    const queryName = `update${capitalize(name)}`
    const graphqlQuery = graphqlUpdate(queryName, payload)
    API.graphql(graphqlOperation(graphqlQuery, { input: payload }))
      .then(({ data }) => {
        const item = data[queryName]
        resolve({ item })
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}
