const actions = {
  SET_STATE: 'users/SET_STATE',
  RESET: 'users/RESET',

  GET: 'users/GET',
  GET_SUCCESS: 'users/GET_SUCCESS',
  GET_FAIL: 'users/GET_FAIL',

  LIST: 'users/LIST',
  LIST_SUCCESS: 'users/LIST_SUCCESS',
  LIST_FAIL: 'users/LIST_FAIL',

  CREATE: 'users/CREATE',
  CREATE_SUCCESS: 'users/CREATE_SUCCESS',
  CREATE_FAIL: 'users/CREATE_FAIL',

  UPDATE: 'users/UPDATE',
  UPDATE_SUCCESS: 'users/UPDATE_SUCCESS',
  UPDATE_FAIL: 'users/UPDATE_FAIL',

  SET_GROUP: 'users/SET_GROUP',
  SET_GROUP_SUCCESS: 'users/SET_GROUP_SUCCESS',
  SET_GROUP_FAIL: 'users/SET_GROUP_FAIL',

  SET_STATUS: 'users/SET_STATUS',
  SET_STATUS_SUCCESS: 'users/SET_STATUS_SUCCESS',
  SET_STATUS_FAIL: 'users/SET_STATUS_FAIL',
}

export default actions
