const urlAdmin = '/admin/'

// export const Partner = {
//   name: 'partners',
//   fields: [
//     { name: 'id', label: 'Id' },
//     { name: 'name', label: 'Nombre' },
//     { name: 'email', label: 'email' },
//   ],
//   menu: {
//     title: 'Partners',
//     key: 'partners',
//     icon: 'fe fe-menu',
//     url: '/partners',
//   },
// }
export const PurchaseOrder = {
  name: 'purchaseOrder',
  fields: [
    // { name: 'id', labe: 'id', placeholder: 'purchaseorder.placeholder.id', required: true },
    { name: 'name', label: 'name', placeholder: 'purchaseorder.placeholder.name', required: true },
    {
      name: 'status',
      label: 'status',
      placeholder: 'purchaseorder.placeholder.status',
      required: true,
    },
    {
      name: 'driverId',
      label: 'driver',
      type: 'select',
      model: 'driver',
      placeholder: 'purchaseorder.placeholder.driver',
      views: [1, 2, 4],
    },
    {
      name: 'amount',
      label: 'amount',
      placeholder: 'purchaseorder.placeholder.amount',
      required: true,
    },
    {
      name: 'date',
      type: 'date',
      label: 'date',
      placeholder: 'purchaseorder.placeholder.date',
      required: true,
    },
    {
      name: 'status',
      label: 'status',
      type: 'purchaseorder-status-select',
      required: true,
      placeholder: 'purchaseorder.placeholder.status',
      views: [2, 4],
    },
  ],
  menu: {
    title: 'Ordenes de pago',
    key: `${urlAdmin}-purchaseOrders`,
    icon: 'fe fe-layers',
    url: `${urlAdmin}purchseorders`,
  },
}

export const Order = {
  name: 'order',
  fields: [
    { name: 'name', label: 'name', placeholder: 'order.placeholder.name', required: true },
    {
      name: 'pickupDate',
      type: 'date',
      label: 'order.pickup.date',
      placeholder: 'order.placeholder.pickup',
      required: true,
    },
    {
      name: 'destinyDate',
      type: 'date',
      label: 'order.date',
      placeholder: 'order.placeholder.date',
      required: true,
    },
    {
      name: 'deliveryDate',
      type: 'date',
      label: 'order.placeholder.delivery',
      placeholder: 'order.placeholder.delivery',
    },
    // { name: 'customerId', type: 'hidden', views: [2, 4], get: 'userId', default: true },
    {
      name: 'customerId',
      label: 'customer',
      type: 'select',
      model: 'customer',
      required: true,
      placeholder: 'order.placeholder.customer',
      views: [2, 4],
    },
    {
      name: 'placeId',
      label: 'order.locate',
      type: 'boolean',
      placeholder: 'order.placeholder.locate',
    },
    // this field is a relational type.
    {
      name: 'evidences',
      label: 'evidence',
      type: 'relational',
      model: 'evidences',
      // allowClear: true,
      placeholder: 'order.placeholder.evidence',
      views: [3, 4],
    },
    {
      name: 'warehouseId',
      label: 'warehouse',
      type: 'select',
      model: 'warehouse',
      required: true,
      placeholder: 'order.placeholder.warehouse',
      views: [2, 4],
    },
    {
      name: 'price',
      label: 'price',
      type: 'number',
      placeholder: 'order.placeholder.price',
      required: true,
    },
    {
      name: 'printed',
      label: 'printed',
      type: 'checkbox',
      placeholder: 'order.placeholder.printed',
    },
    // {
    //   name: 'driver',
    //   label: 'driver',
    //   placeholder: 'order.placeholder.driver',
    // },
    {
      name: 'regionId',
      label: 'region',
      type: 'select',
      model: 'region',
      required: true,
      placeholder: 'order.placeholder.region',
      views: [2, 4],
    },
    {
      name: 'status',
      label: 'status',
      type: 'order-status-select',
      required: true,
      placeholder: 'order.placeholder.status',
      views: [2, 4],
    },
    {
      name: 'notDeliveredReason',
      label: 'notDelivered',
      // type: 'order-status-select',
      required: true,
      placeholder: 'order.placeholder.status',
      views: [2, 4],
    },
    {
      name: 'driverId',
      label: 'driver',
      type: 'select',
      model: 'driver',
      placeholder: 'order.placeholder.driver',
      views: [2, 4],
    },
    {
      name: 'cityId',
      label: 'city',
      type: 'select',
      model: 'city',
      // TODO: when use redux for list, we need these fields
      // fields: ['id', 'name'],
      required: true,
      placeholder: 'order.placeholder.city',
      views: [2, 4],
    },
    {
      name: 'address',
      label: 'address',
      placeholder: 'order.placeholder.address',
      required: true,
    },
    {
      name: 'referenceNumber',
      label: 'referenceNumber', // TODO: rename this translations.
      placeholder: 'order.placeholder.reference',
      required: true,
      views: [2, 3, 4],
    },
    {
      name: 'customerName',
      label: 'customerName', // TODO: rename this translations.
      placeholder: 'order.placeholder.customer',
      views: [2, 3, 4],
    },
    {
      name: 'customerEmail',
      label: 'Email',
      placeholder: 'customer.placeholder.email',
      views: [2, 3, 4],
    },
    {
      name: 'cellphone',
      label: 'cellphone',
      placeholder: 'order.placeholder.cellphone',
      views: [2, 3, 4],
    },
    {
      name: 'productDescription',
      label: 'productDescription',
      placeholder: 'order.placeholder.productDescription',
      views: [2, 3, 4],
    },
    // { name: 'id', type: 'id', label: 'id.id', placeholder: 'id.id' },
  ],
  menu: {
    title: 'Órdenes',
    key: `${urlAdmin}-orders`,
    icon: 'fe fe-layers',
    url: `${urlAdmin}orders`,
  },
}

export const Route = {
  name: 'route',
  fields: [
    { name: 'name', label: 'name', placeholder: 'purchaseorder.placeholder.name', required: true },
    {
      name: 'status',
      label: 'status',
      placeholder: 'purchaseorder.placeholder.status',
      required: true,
    },
    // {
    //   name: 'driverId',
    //   label: 'driver',
    //   type: 'select',
    //   model: 'driver',
    //   placeholder: 'purchaseorder.placeholder.driver',
    //   views: [1, 2, 4],
    // },
    // {
    //   name: 'amount',
    //   label: 'amount',
    //   placeholder: 'purchaseorder.placeholder.amount',
    //   required: true,
    // },
  ],
  menu: {
    title: 'Rutas',
    key: `${urlAdmin}-routes`,
    icon: 'fe fe-map-pin',
    url: `${urlAdmin}routes`,
  },
}

export const Tracking = {
  name: 'tracking',
  fields: [
    // { name: 'id', label: 'Id' },
    { name: 'date', type: 'date', label: 'tracking.placeholder.date', required: true },
    {
      name: 'orderId',
      label: 'order',
      type: 'select',
      model: 'order',
      required: true,
      placeholder: 'tracking.placeholder.order',
      views: [2, 4],
    },
    {
      name: 'statusId',
      label: 'status',
      type: 'select',
      model: 'status',
      required: true,
      placeholder: 'tracking.placeholder.status',
      views: [2, 4],
    },
    {
      name: 'driverId',
      label: 'driver',
      type: 'select',
      model: 'driver',
      required: true,
      placeholder: 'tracking.placeholder.driver',
      views: [2, 4],
    },
  ],
  menu: {
    title: 'Seguimientos',
    key: `${urlAdmin}-trackings`,
    icon: 'fe fe-clipboard',
    url: `${urlAdmin}trackings`,
  },
}

export const Status = {
  name: 'status',
  fields: [
    { name: 'id', label: 'id', placeholder: 'status.placeholder.id', required: true },
    { name: 'name', label: 'name', placeholder: 'status.placeholder.name', required: true },
  ],
  menu: {
    title: 'Estados',
    key: `${urlAdmin}-statuss`,
    icon: 'fe fe-calendar',
    url: `${urlAdmin}statuss`,
  },
}

export const Driver = {
  name: 'driver',
  // roles: [1, 2],
  fields: [
    { name: 'name', label: 'name', placeholder: 'driver.name', required: true },
    { name: 'email', label: 'email', placeholder: 'driver.email', required: true },
    { name: 'rut', label: 'rut', placeholder: 'driver.rut', required: false },
    { name: 'address', label: 'address', placeholder: 'driver.address', required: false },
    { name: 'carPlate', label: 'carPlate', placeholder: 'driver.carplate', required: false },
    { name: 'carModel', label: 'carModel', placeholder: 'driver.model', required: false },
    { name: 'cellphone', label: 'cellphone', placeholder: 'driver.cellphone', required: false },
    // { name: 'id', type: 'id', label: 'id.id', placeholder: 'id.id' },
  ],
  menu: {
    title: 'Choferes',
    key: `${urlAdmin}-drivers`,
    icon: 'fe fe-users',
    url: `${urlAdmin}drivers`,
  },
}

export const Evidence = {
  name: 'evidence',
  fields: [
    {
      name: 'orderId',
      label: 'order',
      type: 'select',
      model: 'order',
      required: true,
      placeholder: 'evidence.placeholder.order',
      views: [1, 2, 4],
    },
    { name: 'name', label: 'description', placeholder: 'evidence.placeholder.description' },
    { name: 'images', label: 'images', placeholder: 'evidence.placeholder.images', views: [2, 4] },
  ],
  menu: {
    title: 'Evidencias',
    key: `${urlAdmin}-evidences`,
    icon: 'fe fe-users',
    url: `${urlAdmin}evidences`,
  },
}

export const Evidenceimage = {
  name: 'evidenceimage',
  fields: [
    { name: 'file', label: 'file', placeholder: 'evidenceimage.placeholder.file' },
    {
      name: 'evidenceId',
      label: 'evidence',
      type: 'select',
      model: 'evidence',
      required: true,
      placeholder: 'evidenceimage.placeholder.evidence',
      views: [2, 4],
    },
  ],
  menu: {
    title: 'Imágenes',
    key: `${urlAdmin}-evidenceimages`,
    icon: 'fe fe-users',
    url: `${urlAdmin}evidenceimages`,
  },
}

export const Customer = {
  name: 'customer',
  // roles: [1, 2],
  fields: [
    { name: 'name', label: 'name', placeholder: 'customer.placeholder.name', required: true },
    { name: 'email', label: 'email', placeholder: 'customer.placeholder.email' },
    { name: 'prefix', label: 'prefix', placeholder: 'customer.placeholder.prefix' },
    // {
    //   name: 'fixedPrice',
    //   label: 'fixed.price',
    //   type: 'checkbox',
    //   placeholder: 'customer.fixed.price',
    // },
    // { name: 'id', type: 'id', label: 'id.id', placeholder: 'id.id' },
  ],
  menu: {
    title: 'Clientes',
    key: `${urlAdmin}-customers`,
    icon: 'fe fe-users',
    url: `${urlAdmin}customers`,
  },
}

// export const Address = {
//   name: 'address',
//   // roles: [1, 4],
//   fields: [
//     { name: 'number', label: 'address', placeholder: 'address.placeholder' },
//     // { name: 'id', type: 'id', label: 'id.id', placeholder: 'id.id' },
//   ],
//   menu: {
//     title: 'Direcciones',
//     key: `${urlAdmin}-addresss`,
//     icon: 'fe fe-menu',
//     url: `${urlAdmin}addresss`,
//   },
// }

export const Region = {
  name: 'region',
  fields: [
    { name: 'id', label: 'id', placeholder: 'region.placeholder.id', required: true },
    { name: 'name', label: 'name', placeholder: 'region.placeholder.name', required: true },
  ],
  menu: {
    title: 'Regiones',
    key: `${urlAdmin}-regions`,
    icon: 'lnr lnr-earth',
    url: `${urlAdmin}regions`,
  },
}

export const City = {
  name: 'city',
  fields: [
    { name: 'id', label: 'id', placeholder: 'city.placeholder.id', required: true, disabled: true },
    { name: 'name', label: 'name', placeholder: 'city.placeholder.name', required: true },
    {
      name: 'regionId',
      label: 'region',
      type: 'select',
      model: 'region',
      required: true,
      placeholder: 'city.placeholder.region',
      views: [2, 4],
    },
  ],
  menu: {
    title: 'Comunas',
    key: `${urlAdmin}-citys`,
    icon: 'lnr lnr-map-marker',
    url: `${urlAdmin}citys`,
  },
}

export const Warehouse = {
  name: 'warehouse',
  fields: [
    { name: 'name', label: 'name', placeholder: 'customer.name', required: true },
    // {
    //   name: 'default',
    //   type: 'checkbox',
    //   label: 'wherehouse',
    //   placeholder: 'wherehouse.placeholder',
    // },
    {
      name: 'default',
      label: 'default',
      type: 'checkbox',
      placeholder: 'warehouse.placeholder.default',
    },
    {
      name: 'fixedPrice',
      label: 'fixed.price',
      type: 'checkbox',
      placeholder: 'warehouse.placeholder.fixed.price',
    },
    { name: 'price', label: 'price', type: 'number', placeholder: 'warehouse.placeholder.price' },
    {
      name: 'paymentAmount',
      label: 'payment.amount',
      type: 'number',
      placeholder: 'warehouse.placeholder.payment.amount',
    },

    // { name: 'customerId', type: 'hidden', views: [2, 4] },
    {
      name: 'customerId',
      label: 'customer',
      type: 'select',
      model: 'customer',
      required: true,
      placeholder: 'warehouse.placeholder.customer',
      views: [2, 4],
    },
    {
      name: 'regionId',
      label: 'region',
      type: 'select',
      model: 'region',
      required: true,
      placeholder: 'warehouse.placeholder.region',
      views: [2, 4],
    },
    {
      name: 'cityId',
      label: 'city',
      type: 'select',
      model: 'city',
      // TODO: when use redux for list, we need these fields
      // fields: ['id', 'name'],
      required: true,
      placeholder: 'warehouse.placeholder.city',
      views: [2, 4],
    },
    {
      name: 'address',
      label: 'address',
      placeholder: 'warehouse.placeholder.address',
      required: true,
    },

    // {
    //   name: 'default',
    //   type: 'checkbox',
    //   label: 'default',
    //   placeholder: 'warehouse.placeholder',
    // },
    // { name: 'id', type: 'id', label: 'id.id', placeholder: 'id.id' },
  ],
  menu: {
    title: 'Almacenes',
    key: `${urlAdmin}-warehouses`,
    icon: 'lnr lnr-apartment',
    url: `${urlAdmin}warehouses`,
  },
}

export const Price = {
  name: 'price',
  fields: [
    { name: 'price', label: 'price', type: 'number', placeholder: 'price.name', required: true },
    {
      name: 'cityId',
      label: 'city',
      type: 'select',
      model: 'city',
      // TODO: when use redux for list, we need these fields
      // fields: ['id', 'name'],
      required: true,
      placeholder: 'price.placeholder.city',
      views: [2, 4],
    },
    {
      name: 'warehouseId',
      label: 'warehouse',
      type: 'select',
      model: 'warehouse',
      // TODO: when use redux for list, we need these fields
      // fields: ['id', 'name'],
      required: true,
      placeholder: 'price.placeholder.warehouse',
      views: [2, 4],
    },
  ],
  menu: {
    title: 'Precios',
    key: `${urlAdmin}-prices`,
    icon: 'fe fe-dollar-sign',
    url: `${urlAdmin}prices`,
  },
}

// export default [
//   Order,
//   Tracking,
//   Status,
//   Driver,
//   Customer,
//   // Address,
//   Region,
//   City,
//   Warehouse,
//   Price,
// ]
