import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'topBar.issuesHistory': 'Histórico Tareas',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',

  id: 'Id',
  date: 'Fecha',
  createdAt: 'Fecha de creación',
  'forms.button.save': 'Guardar',
  'forms.button.create': 'Crear',
  'forms.button.cancel': 'Cancelar',
  'forms.button.back': 'Atrás',
  'forms.button.assign': 'Asignar',
  'forms.button.locate': 'Geolocalizar',
  'forms.button.reassign': 'Reasignar',
  'forms.button.delivered': 'Entregada',
  'forms.button.process': 'Procesar',
  'forms.button.clear': 'Limpiar',
  'forms.button.unassign': 'Deshacer Asignación',
  'forms.button.enable': 'Habilitar',
  'forms.button.disable': 'Deshabilitar',
  'forms.button.remove': 'Eliminar',
  'forms.button.restore': 'Restaurar',
  'forms.button.pay': 'Pagar',
  'forms.button.resend': 'Enviar de Nuevo',
  'forms.button.get.code': 'Obtener código',
  'forms.button.get.code.again': 'Obtener código nuevamente',
  'forms.button.print.qr': 'Imprimir',

  email: 'Correo electrónico',
  rut: 'Rut',
  carPlate: 'Placa del vehículo',
  carModel: 'Modelo del vehículo',
  model: 'Modelo',

  'driver.name': 'Nombre del chofer',
  'driver.email': 'Correo electrónico del chofer',
  'driver.carplate': 'Placa del vehículo',
  'driver.cellphone': 'Celular',
  'driver.model': 'Modelo del vehículo',
  'driver.address': 'Dirección del chofer',
  'driver.rut': 'Rut del chofer',
  'fixed.price': 'Precio fijo',
  'customer.fixed.price': 'Precio fijo del cliente',
  groups: 'Grupos',
  'fields.required': 'Este campo es requerido.',

  name: 'Nombre',
  password: 'Contraseña',
  customer: 'Cliente',
  prefix: 'Prefijo',
  'customer.placeholder.name': 'Nombre del cliente',
  'customer.placeholder.email': 'Correo electrónico del cliente',
  'customer.placeholder.password': 'Contraseña del cliente',
  'customer.placeholder.prefix': 'Prefijo del cliente',

  admin: 'Administrador',
  qiwenty: 'Qiwenty',
  'user.placeholder.admin': 'Grupo Administrador',
  'user.placeholder.qiwenty': 'Grupo Qiwenty',
  'user.placeholder.customer': 'Grupo Cliente',
  'user.placeholder.driver': 'Grupo Chofer',
  'user.placeholder.name': 'Nombre del usuario',
  'user.placeholder.email': 'Correo electrónico del usuario',
  'user.placeholder.password': 'Contraseña del usuario',

  'driver.placeholder.name': 'Nombre del chofer',
  'driver.placeholder.email': 'Correo electrónico del chofer',
  'driver.placeholder.password': 'Contraseña del chofer',
  'driver.placeholder.carplate': 'Placa del vehículo',
  'driver.placeholder.model': 'Modelo del vehículo',
  'driver.placeholder.address': 'Dirección del chofer',
  'driver.placeholder.rut': 'Rut del chofer',
  'driver.placeholder.cellphone': 'Celular del Chofer',

  'purchaseorder.placeholder.id': 'Id de la compra',
  'purchaseorder.placeholder.name': 'Nombre de la compra',
  'purchaseorder.placeholder.status': 'Estado de la compra',
  'purchaseorder.placeholder.driver': 'Chofer de la compra',
  'purchaseorder.placeholder.amount': 'Total de la compra',
  'purchaseorder.placeholder.date': 'Fecha de la compra',
  'purchaseorder.placeholder.number': 'Número de la compra',

  amount: 'Total',
  'empty.select': 'Sin datos.',
  'payment.amount': 'Monto a pagar',
  price: 'Precio',
  number: 'Número',
  printed: 'Impreso',
  region: 'Región',
  city: 'Comuna',
  driver: 'Chofer',
  status: 'Estado',
  referenceNumber: '# Referencia',
  customerName: 'Nombre del cliente',
  cellphone: 'Celular',
  productDescription: 'Descripción',
  order: 'Despacho',
  'order.locate': 'Geolocalizado',
  'order.name': 'Número',
  'order.date': 'Fecha',
  'order.placeholder.date': 'Fecha de entrega',
  'order.pickup.date': 'Fecha de recogida',
  'order.placeholder.pickup': 'Fecha de recogida',
  'order.placeholder.delivery': 'Fecha entregado',
  'order.placeholder.price': 'Precio del despacho',
  'order.placeholder.amount': 'Total del despacho',
  'order.placeholder.printed': 'Despacho impreso',
  'order.placeholder.pay.amount': 'Monto a pagar',
  'order.placeholder.name': 'Nombre del despacho',
  'order.placeholder.warehouse': 'Almacén del despacho',
  'order.placeholder.region': 'Región del despacho',
  'order.placeholder.city': 'Comuna del despacho',
  'order.placeholder.address': 'Dirección del despacho',
  'order.placeholder.status': 'Estado del despacho',
  'order.placeholder.customer': 'Cliente del despacho',
  'order.placeholder.driver': 'Chofer del despacho',
  'order.placeholder.evidence': 'Evidencia del despacho',
  'order.placeholder.reference': 'Número de referencia',
  'order.placeholder.cellphone': 'Celular',
  'order.placeholder.description': 'Descripción del producto',
  'order.placeholder.evidenceDescription': 'Descripción de la evidencia',
  'order.placeholder.locate': 'Geolocalización de la orden',

  'order.placeholder.search': ' Buscar',
  'order.placeholder.search.customer': 'Buscar por cliente',
  'order.placeholder.search.name': 'Nombre del Despacho',
  'order.placeholder.search.number': 'Número del Despacho',
  'order.placeholder.search.label': 'Nombre',
  'order.placeholder.assign.driver': 'Buscar chofer',
  'order.placeholder.create.pay': 'Crear pago',
  'order.placeholder.create.charge': 'Crear cobro',

  'see.evidence': 'Ver evidencia',
  'buttons.create.evidence': 'Crear evidencia',
  evidence: 'Evidencia',
  file: 'Archivo',
  description: 'Descripción',
  'evidence.placeholder.order': 'Despacho de la evidencia',
  'evidence.placeholder.driver': 'Chofer de la evidencia',
  'evidence.placeholder.description': 'Descripción de la evidencia',
  'evidence.placeholder.search.order': 'Buscar por Despacho',
  'evidenceimage.placeholder.file': 'Archivo de la imagen',
  'evidenceimage.placeholder.evidence': 'Evidencia de la imagen',

  wherehouse: 'Almacen principal',
  'wherehouse.placeholder': 'Principal',
  warehouse: 'Almacén',
  'warehouse.placeholder.name': 'Nombre del almacén',
  'warehouse.placeholder.default': 'Principal',
  'warehouse.placeholder.fixed.price': 'Precio fijo del almacén',
  'warehouse.placeholder.customer': 'Cliente del almacén',
  'warehouse.placeholder.price': 'Precio del almacén',
  'warehouse.placeholder.payment.amount': 'Monto a pagar',
  'warehouse.placeholder.region': 'Región del almacén',
  'warehouse.placeholder.city': 'Comuna del almacén',
  'warehouse.placeholder.address': 'Dirección del almacén',

  'tracking.placeholder.order': 'Despacho del seguimiento',
  'tracking.placeholder.driver': 'Chofer del seguimiento',
  'tracking.placeholder.status': 'Estado del seguimiento',
  'tracking.placeholder.date': 'Fecha del seguimiento',

  'status.placeholder.id': 'Id del estado',
  'status.placeholder.name': 'Nombre del estado',
  'city.placeholder.id': 'Id de la comuna',
  'city.placeholder.name': 'Nombre de la comuna',
  'city.placeholder.region': 'Región de la comuna',
  'region.placeholder.id': 'Id de la región',
  'region.placeholder.name': 'Nombre de la región',

  'price.name': 'Precio',
  'price.placeholder.city': 'Comuna del precio',
  'price.placeholder.warehouse': 'Almacén del precio',

  default: 'Principal',

  address: 'Dirección',
  'address.placeholder': 'Dirección del almacen',

  error: 'Error',
  'models.create.error': 'Ha ocurrido un error al crear.',

  draft: 'Borrador',
  delivered: 'Entregado',
  notDelivered: 'No Entregado',
  processed: 'Procesado',
  assigned: 'Asignado',
  transit: 'Tránsito',
  delayed: 'Retrasado',
  payed: 'Pagada',

  enabled: 'Habilitado',
  disabled: 'Deshabilitado',

  wrongPlace: 'Domicilio incorrecto o incompleto',
  refused: 'Rechazado por el comprador',
  placeNotVisited: 'Domicilio no visitado',
  reprogramed: 'Reprogramado por el comprador',
  noOne: 'No hay nadie en el domicilio',
}

export default {
  locale: 'es-ES',
  localeAntd,
  messages,
}
