import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import phe from 'print-html-element'
import {
  list,
  create,
  update,
  get,
  // getOrder,
  createQiwentyCustomer,
  warehousesByCustomers,
  ordersByCustomers,
  ordersByStatus,
  ordersByCustomerStatus,
  pricesByWarehouse,
  evidencesByOrder,
  evidencesByFilter,
  evidencesByOrderName,
  createCity,
  remove,
  ordersByName,
  ordersByStatusName,
  ordersByCustomerByName,
  ordersByCustomerStatusName,
  ordersByDriver,
  ordersByDriverByStatus,
  ordersByDate,
  ordersByDateByStatus,
  // createEvidence,
  purchasesOrdersStatus,
  purchasesOrdersStatusDate,
  purchasesOrdersDriverStatus,
  purchasesOrdersDriverStatusDate,
  ordersFromPurchase,
  ordersByStatusDate,
  ordersByDriversStatus,
  ordersByDriversStatusDate,
} from 'services/cruds'
import { createUser } from 'services/auth'
import { generateQr } from 'services'
import { Log } from 'utils'
import actions from './actions'

export function* LIST({
  model,
  method,
  driverId,
  startDate,
  endDate,
  customerId,
  status,
  warehouseId,
  orderId,
  orderName,
  payload,
  nextToken = null,
}) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loading: true, created: false },
    })
    let callMethod
    let params
    switch (method) {
      case 'warehousesByCustomer':
        callMethod = warehousesByCustomers
        params = { id: customerId }
        break
      case 'ordersByCustomer':
        callMethod = ordersByCustomers
        params = { id: customerId }
        break
      // TODO: remove
      case 'ordersByStatusOld':
        callMethod = ordersByStatus
        params = { nextToken, status }
        break
      case 'ordersByCustomerByStatus':
        callMethod = ordersByCustomerStatus
        params = { nextToken, customerId, status }
        break
      case 'ordersByName':
        callMethod = ordersByName
        params = { name: orderName }
        break
      // TODO: check to remove
      case 'ordersByStatusByName':
        callMethod = ordersByStatusName
        params = { name: orderName, status }
        break
      case 'ordersByStatusName':
        callMethod = ordersByStatusName
        params = { name: orderName, status }
        break
      case 'ordersByCustomerByName':
        callMethod = ordersByCustomerByName
        params = { name: orderName, customerId }
        break
      case 'ordersByCustomerByStatusByName':
        callMethod = ordersByCustomerStatusName
        params = { name: orderName, customerId, status }
        break
      case 'ordersByDriver':
        callMethod = ordersByDriver
        params = { driverId }
        break
      case 'ordersByDriverByStatus':
        callMethod = ordersByDriverByStatus
        params = { driverId, status }
        break
      case 'ordersByDate':
        callMethod = ordersByDate
        params = { startDate, endDate }
        break
      // TODO: Remove
      case 'ordersByDateByStatus':
        callMethod = ordersByDateByStatus
        params = { startDate, endDate, status, nextToken }
        break
      // news filters used in payments
      case 'ordersByStatus':
        callMethod = ordersByStatus
        params = { status, nextToken }
        break
      case 'ordersByStatusDate':
        callMethod = ordersByStatusDate
        params = { status, nextToken, destinyDateStart: startDate, destinyDateEnd: endDate }
        break
      case 'ordersByDriversStatus':
        callMethod = ordersByDriversStatus
        params = { driverId, status, nextToken }
        break
      case 'ordersByDriversStatusDate':
        callMethod = ordersByDriversStatusDate
        params = {
          driverId,
          status,
          nextToken,
          destinyDateStart: startDate,
          destinyDateEnd: endDate,
        }
        break
      // end new filters used in payments
      case 'pricesByWarehouse':
        callMethod = pricesByWarehouse
        params = { id: warehouseId }
        break
      case 'evidencesByOrder':
        callMethod = evidencesByOrder
        params = { id: orderId }
        break
      case 'evidencesByFilter':
        callMethod = evidencesByFilter
        params = { nextToken }
        break
      case 'evidencesByOrderName':
        callMethod = evidencesByOrderName
        params = { name: orderName, nextToken }
        break
      case 'purchasesOrdersStatus':
        callMethod = purchasesOrdersStatus
        params = { ...payload, nextToken }
        break
      case 'purchasesOrdersStatusDate':
        callMethod = purchasesOrdersStatusDate
        params = { ...payload, nextToken }
        break
      case 'purchasesOrdersDriverStatus':
        callMethod = purchasesOrdersDriverStatus
        params = { ...payload, nextToken }
        break
      case 'purchasesOrdersDriverStatusDate':
        callMethod = purchasesOrdersDriverStatusDate
        params = { ...payload, nextToken }
        break
      case 'ordersFromPurchase':
        callMethod = ordersFromPurchase
        params = { ...payload, nextToken }
        break
      default:
        callMethod = list
        params = model
        break
    }

    const result = yield call(callMethod, params)

    yield put({
      name: model.name,
      type: actions.LIST_SUCCESS,
      payload: { ...result, appendItems: nextToken !== null },
    })
    // perhaps if needed in other cases.
    // if (method === 'warehousesByCustomer') {
    //   let prices = []
    //   items.forEach((e) => {
    //     prices = prices.concat(e.prices.items)
    //   })
    //   yield put({
    //     name: 'price',
    //     type: actions.LIST_SUCCESS,
    //     payload: prices,
    //   })
    // }
  } catch (error) {
    yield put({
      name: model.name,
      type: actions.LIST_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al obtener los datos.',
    })
  }
}

export function* GET({ model, id }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    // , this params, (method)
    // let callMethod
    // const params = { ...model, id }
    // switch (method) {
    //   case 'order':
    //     callMethod = getOrder
    //     break
    //   default:
    //     callMethod = get
    //     break
    // }

    const item = yield call(get, { ...model, id })

    yield put({
      name: model.name,
      type: actions.GET_SUCCESS,
      payload: item,
    })
  } catch (error) {
    yield put({
      name: model.name,
      type: actions.GET_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al obtener los datos.',
    })
  }
}

export function* CREATE({ model, payload, method }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    // // JUST FOR CREATE CUSTOMERS
    // let user
    // if (model.name === 'customer') {
    //   user = yield call(createUser, payload)
    //   payload.id = user.userSub
    //   // delete payload.password
    // }

    let callMethod
    switch (method) {
      case 'createCity':
        callMethod = createCity
        break
      default:
        callMethod = create
        break
    }

    const item = yield call(callMethod, { name: model.name, payload })

    yield put({
      name: model.name,
      type: actions.CREATE_SUCCESS,
      payload: item,
    })
  } catch (error) {
    yield put({
      name: model.name,
      type: actions.CREATE_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al crear.',
    })
  }
}

export function* CREATE_PURCHASE_ORDER({ model, payload, ids }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    const { item } = yield call(create, { name: model.name, payload })
    const { id: purchaseOrderId } = item

    const state = yield select()
    const { items, itemsByIds } = state.order

    yield all(
      ids.map(e => {
        const { name, destinyDate } = items[itemsByIds[e]]
        return call(update, {
          name: 'order',
          payload: {
            id: e,
            orderPurchaseOrderId: purchaseOrderId,
            status: 'PAYMENTPENDING',
            name,
            destinyDate,
          },
        })
      }),
    )

    yield put({
      name: model.name,
      type: actions.CREATE_PURCHASE_ORDER_SUCCESS,
      payload: item,
    })
    yield put({
      name: 'order',
      type: actions.REMOVE_SUCCESS,
      payload: {
        items: ids,
      },
    })
  } catch (error) {
    yield put({
      name: model.name,
      type: actions.CREATE_PURCHASE_ORDER_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al crear un pago.',
    })
  }
}

export function* UPDATE_ORDER({ model, ids }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    const state = yield select()
    const { items: orders, itemsByIds } = state.order

    yield all(
      ids.map(e => {
        const { name, destinyDate } = orders[itemsByIds[e]]
        return call(update, {
          name: 'order',
          payload: { id: e, orderPurchaseOrderId: null, status: 'DELIVERED', name, destinyDate },
        })
      }),
    )

    yield put({
      name: model.name,
      type: actions.REMOVE_SUCCESS,
      payload: { items: ids },
    })
  } catch (error) {
    yield put({
      name: model.name,
      type: actions.UPDATE_ORDER_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al actualizar.',
    })
  }
}

export function* REMOVE_PURCHASE_ORDER({ model, payload }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    const state = yield select()
    const { items } = state.order

    yield all(
      items.map(e => {
        const { name, destinyDate } = e
        return call(update, {
          name: 'order',
          payload: {
            id: e.id,
            orderPurchaseOrderId: null,
            status: 'DELIVERED',
            name,
            destinyDate,
          },
        })
      }),
    )

    yield call(remove, { name: model.name, payload })

    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loading: false, created: true },
    })
  } catch (error) {
    yield put({
      name: model.name,
      type: actions.REMOVE_PURCHASE_ORDER_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al eliminar.',
    })
  }
}

export function* PAY_PURCHASE_ORDER({ model, payload }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    const state = yield select()
    const { items: orders } = state.order
    const { item } = state.purchaseOrder

    yield all(
      orders.map(e => {
        const { name, destinyDate } = e
        return call(update, {
          name: 'order',
          payload: {
            id: e.id,
            status: 'PAYED',
            name,
            destinyDate,
          },
        })
      }),
    )

    const { date } = item
    yield call(update, { name: model.name, payload: { ...payload, date, status: 'PAYED' } })

    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loading: false, created: true },
    })
  } catch (error) {
    yield put({
      name: model.name,
      type: actions.REMOVE_PURCHASE_ORDER_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al actualizar.',
    })
  }
}

export function* UPDATE({ model, payload }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    const item = yield call(update, { name: model.name, payload })
    yield put({
      name: model.name,
      type: actions.UPDATE_SUCCESS,
      payload: item,
    })
  } catch (error) {
    yield put({
      name: model.name,
      type: actions.UPDATE_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al actualizar.',
    })
  }
}

export function* RESET({ model }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { fetched: false, item: null },
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export function* RESET_LIST({ model }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { fetched: false, items: [] },
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

export function* SET_ITEM({ model, payload }) {
  try {
    const state = yield select()
    const { itemsByIds, items } = state[model.name]
    const item = items[itemsByIds[payload.id]]
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { item },
    })
  } catch (error) {
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error.',
    })
  }
}

// // CUSTOMERS
// export function* LIST_WAREHOUSE({ model, method }) {
//   try {
//     yield put({
//       name: model.name,
//       type: actions.SET_STATE,
//       payload: { loading: true, created: false },
//     })
//     console.log(method)

//     const items = yield call(warehousesByCustomers, model)

//     yield put({
//       name: model.name,
//       type: actions.LIST_SUCCESS,
//       payload: items,
//     })
//   } catch (error) {
//     yield put({
//       name: model.name,
//       type: actions.LIST_FAIL,
//       payload: error,
//     })

//     notification.warning({
//       message: 'Error',
//       description: 'Ha ocurrido un error al obtener los datos.',
//     })
//   }
// }

// QIWENTY CREATE CUSTOMER
export function* CREATE_CUSTOMER({ model, payload }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    const userData = {
      email: payload.useremail,
      password: payload.userpassword,
      name: payload.username,
      groups: JSON.stringify(['Customer']),
    }

    yield call(createUser, userData)
    // payload.id = user.userSub
    const customerData = {
      fixedPrice: payload.fixedPrice,
      email: payload.email,
      name: payload.name,
      users: [payload.useremail],
    }

    const item = yield call(createQiwentyCustomer, { name: model.name, payload: customerData })

    yield put({
      name: model.name,
      type: actions.CREATE_CUSTOMER_SUCCESS,
      payload: item,
    })
  } catch (error) {
    yield put({
      name: model.name,
      type: actions.CREATE_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al crear.',
    })
  }
}

export function* CREATE_DRIVER({ model, payload }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loading: true },
    })
    const userData = {
      name: payload.name,
      email: payload.email,
      password: payload.password,
      groups: JSON.stringify(['Driver']),
    }

    yield call(createUser, userData)
    // payload.id = user.userSub
    const data = {
      email: payload.email,
      name: payload.name,
      id: payload.email,
    }

    const item = yield call(createQiwentyCustomer, { name: model.name, payload: data })

    yield put({
      name: model.name,
      type: actions.CREATE_DRIVER_SUCCESS,
      payload: item,
    })
  } catch (error) {
    yield put({
      name: model.name,
      type: actions.CREATE_DRIVER_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al crear.',
    })
  }
}

// TODO: improve create evidence.
// export function* CREATE_EVIDENCE({ model, payload }) {
//   try {
//     yield put({
//       name: model.name,
//       type: actions.SET_STATE,
//       payload: { loading: true },
//     })
//     const userData = {
//       name: payload.name,
//       email: payload.email,
//       password: payload.password,
//       groups: JSON.stringify(['Driver']),
//     }

//     yield call(createEvidence, userData)
//     // payload.id = user.userSub
//     const data = {
//       email: payload.email,
//       name: payload.name,
//     }

//     const item = yield call(createQiwentyCustomer, { name: model.name, payload: data })

//     yield put({
//       name: model.name,
//       type: actions.CREATE_DRIVER_SUCCESS,
//       payload: item,
//     })
//   } catch (error) {
//     yield put({
//       name: model.name,
//       type: actions.CREATE_DRIVER_FAIL,
//       payload: error,
//     })

//     notification.warning({
//       message: 'Error',
//       description: 'Ha ocurrido un error al crear.',
//     })
//   }
// }

export function* REMOVE({ model, payload }) {
  try {
    yield put({
      name: model.name,
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    const { items } = payload
    yield all(
      items.map(e => {
        return call(remove, { name: model.name, payload: { id: e } })
      }),
    )

    yield put({
      name: model.name,
      type: actions.REMOVE_SUCCESS,
      payload,
    })
  } catch (error) {
    yield put({
      name: model.name,
      type: actions.REMOVE_FAIL,
      payload: error,
    })

    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al eliminar.',
    })
  }
}

const printOptions = {
  // printMode: "popup",
  // printBodyOptions: null
  // templateString: '<header>I\'m part of the template header</header>{{printBody}}<footer>I\'m part of the template footer</footer>'
}

export function* GENERATE_QR({ payload }) {
  try {
    yield put({
      name: 'order',
      type: actions.SET_STATE,
      payload: { loading: true },
    })

    const { ids } = payload

    const state = yield select()
    const { items, itemsByIds } = state.order
    let printLabelS = ''

    const imageQrs = yield all(
      ids.map(e => {
        return call(generateQr, { id: e })
      }),
    )

    for (let i = 0; i < imageQrs.length; i += 1) {
      const item = items[itemsByIds[imageQrs[i].id]]
      item.printed = true
      const {
        name,
        referenceNumber,
        customerName,
        address,
        city: { name: cityName },
        cellphone,
      } = item
      const printLabel = `
      <!DOCTYPE html>
      <html>
        <style>
          table {
            width: 100mm;
          }
        </style>
        <body>
          <table>
            <tr><td style="width: 50%;"><img src="${imageQrs[i].image}"></td><td style="text-align: center;"><h4>ID: ${name}</h4></td></tr>
            <tr><td colspan="2"><h4>PEDIDO: ${referenceNumber}</h4></td></tr>
            <tr><td colspan="2"><h4>CLIENTE: ${customerName}</h4></td></tr>
            <tr><td colspan="2"><h4>DIR: ${address}</h4></td></tr>
            <tr><td colspan="2"><h4>COMUNA: ${cityName}</h4></td></tr>
            <tr><td colspan="2"><h4>PHONO: ${cellphone}</h4></td></tr>
          </table>
          <h4 style="page-break-after: always;">WWW.QIWENTY.COM</h4>
        </body>
      </html>
      `
      printLabelS += printLabel
    }

    phe.printHtml(printLabelS, printOptions)

    // TODO: Review why we need to pass a keys values.
    yield all(
      ids.map(e => {
        const { status, name, destinyDate } = items[itemsByIds[e]]
        return call(update, {
          name: 'order',
          payload: {
            id: e,
            printed: true,
            status,
            name,
            destinyDate,
          },
        })
      }),
    )

    yield put({
      name: 'order',
      type: actions.SET_STATE,
      payload: { loading: false, items },
    })
  } catch (error) {
    // console.log(JSON.stringify(error));
    Log(error)

    notification.warning({
      message: 'Error',
      description: 'Ha ocurrido un error al generar el QR.',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.CREATE_PURCHASE_ORDER, CREATE_PURCHASE_ORDER),
    takeEvery(actions.PAY_PURCHASE_ORDER, PAY_PURCHASE_ORDER),
    takeEvery(actions.REMOVE_PURCHASE_ORDER, REMOVE_PURCHASE_ORDER),
    takeEvery(actions.UPDATE_ORDER, UPDATE_ORDER),
    takeEvery(actions.CREATE_CUSTOMER, CREATE_CUSTOMER),
    takeEvery(actions.CREATE_DRIVER, CREATE_DRIVER),
    // takeEvery(actions.CREATE_EVIDENCE, CREATE_EVIDENCE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.GET, GET),
    takeEvery(actions.RESET, RESET),
    takeEvery(actions.RESET_LIST, RESET_LIST),
    takeEvery(actions.SET_ITEM, SET_ITEM),
    takeEvery(actions.REMOVE, REMOVE),
    takeEvery(actions.GENERATE_QR, GENERATE_QR),
  ])
}
