const actions = {
  SET_STATE: 'assignorders/SET_STATE',
  SET_DRIVER: 'assignorders/SET_DRIVER',
  SET_ORDER: 'assignorders/SET_ORDER',
  ASSIGN: 'assignorders/ASSIGN',
  UNASSIGN: 'assignorders/UNASSIGN',
  DELIVERED: 'assignorders/DELIVERED',
  CHANGE_STATUS: 'assignorders/CHANGE_STATUS',
  LOCATE: 'assignorders/LOCATE',
  ASSIGN_SUCCESS: 'assignorders/ASSIGN_SUCCESS',
  UNASSIGN_SUCCESS: 'assignorders/UNASSIGN_SUCCESS',
  DELIVERED_SUCCESS: 'assignorders/DELIVERED_SUCCESS',
}

export default actions
