import { API, graphqlOperation } from 'aws-amplify'
import { Log } from 'utils'

// const listCustomers = /* GraphQL */ `
//   query listCustomers {
//     listCustomers {
//       items {
//         id
//         name
//       }
//     }
//   }
// `

// export default async () => {
//   return new Promise((resolve, reject) => {
//     API.graphql(graphqlOperation(listCustomers))
//       .then(({ data }) => {
//         const { items } = data.listCustomers
//         resolve(items)
//       })
//       .catch(error => {
//         reject(error)
//       })
//   })
// }

const graphqlUpdate = () => {
  const update = `
    mutation updateOrder ($input: UpdateOrderInput!) {
      updateOrder (input: $input) {
        id
      }
    }
  `
  return update
}

export default async payload => {
  return new Promise((resolve, reject) => {
    const graphqlQuery = graphqlUpdate()
    API.graphql(graphqlOperation(graphqlQuery, { input: payload }))
      .then(({ data }) => {
        const item = data.updateOrder
        resolve({ item })
      })
      .catch(error => {
        Log(error)
        reject(error)
      })
  })
}
