import actions from './actions'

const initialState = {
  customerId: null,
  driverId: null,
  loading: false,
}

const assignOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.UNASSIGN_SUCCESS: {
      return { ...state, items: action.payload }
    }
    case actions.DELIVERED_SUCCESS: {
      return { ...state, items: action.payload }
    }
    //   const { itemsIds, itemsByIds, orders } = action.payload
    //   const { driverId } = state
    //   itemsIds.forEach(e => {
    //     orders[itemsByIds[e]].driverId = driverId
    //   });
    //   return { ...state, items: orders }
    // }
    default:
      return state
  }
}

export default assignOrdersReducer
