import { Storage } from 'aws-amplify'
import { v4 } from 'uuid'
import * as Sentry from '@sentry/react'

// eslint-disable-next-line import/prefer-default-export
export const capitalize = value => {
  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`
}

export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => {
      Log(error)
      return reject(error)
    }
  })
}

export const uploadFile = async (prefix, file) => {
  return new Promise(resolve => {
    try {
      const image = Storage.put(`${prefix}${v4()}`, file, {
        contentType: 'image/png',
      })
      resolve(image)
    } catch (err) {
      Log(err)

      console.log(err)
    }
  })
}

export const getListFiles = async prefix => {
  return new Promise(resolve => {
    try {
      console.log(prefix)
      const images = Storage.list(`${prefix}/`)
      resolve(images)
    } catch (err) {
      Log(err)

      console.log(err)
    }
  })
}

export const getFile = async filePath => {
  const promise = Storage.get(filePath)
  return promise.then(images => {
    return images
  })
}

export const removeFile = filePath => {
  return new Promise(resolve => {
    try {
      const image = Storage.remove(filePath)
      resolve(image)
    } catch (err) {
      Log(err)

      console.log(err)
    }
  })
}

const CITIES = {
  'puente alto': 'puente_alto',
  pirque: 'pirque',
  'san josé de maipo': 'san_jose_maipo',
  'san jose de maipo': 'san_jose_maipo',
  melipilla: 'melipilla',
  'maría pinto': 'maria_pinto',
  'maria pinto': 'maria_pinto',
  curacaví: 'curacavi',
  curacavi: 'curacavi',
  alhue: 'alhue',
  'san pedro': 'san_pedro',
  talagante: 'talagante',
  'el monte': 'el_monte',
  'isla de maipo': 'isla_maipo',
  'padre hurtado': 'padre_hurtado',
  peñaflor: 'penaflor',
  buin: 'buin',
  'calera de Tango': 'calera_tango',
  paine: 'paine',
  'san bernardo': 'san_bernardo',
  colina: 'colina',
  lampa: 'lampa',
  'til til': 'til_til',
  cerrillos: 'cerrillos',
  'cerro navia': 'cerro_navia',
  conchalí: 'conchali',
  conchali: 'conchali',
  'el bosque': 'el_bosque',
  'estación central': 'estacion_central',
  'estacion central': 'estacion_central',
  huechuraba: 'huechuraba',
  independencia: 'independencia',
  'la cisterna': 'la_cisterna',
  'la florida': 'la_florida',
  'la granja': 'la_granja',
  'la pintana': 'la_pintana',
  'la reina': 'la_reina',
  'las condes': 'las_condes',
  'Lo barnechea': 'lo_barnechea',
  'Lo espejo': 'lo_espejo',
  'Lo prado': 'lo_prado',
  macul: 'macul',
  maipú: 'maipu',
  maipu: 'maipu',
  ñuñoa: 'nunoa',
  nunoa: 'nunoa',
  'pedro aguirre cerda': 'pedro_aguirre_cerda',
  peñalolén: 'penalolen',
  peñalolen: 'penalolen',
  providencia: 'providencia',
  pudahuel: 'pudahuel',
  quilicura: 'quilicura',
  'quinta normal': 'quinta_normal',
  recoleta: 'recoleta',
  renca: 'renca',
  'san joaquín': 'san_joaquin',
  'san joaquin': 'san_joaquin',
  'san miguel': 'san_miguel',
  'san ramón': 'san_ramon',
  'san ramon': 'san_ramon',
  santiago: 'santiago',
  vitacura: 'vitacura',
}
export const getCityId = cityName => {
  const tmpName = cityName.toLowerCase()
  if (CITIES[tmpName]) {
    return CITIES[tmpName]
  }
  return 'santiago'
}

export const Log = message => {
  const result = message
  // if (typeof result === 'object') {
  //  result = JSON.stringify(result)
  // }
  // console.log(result)
  Sentry.captureException(result)
}
